<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	export const color = getContext('font-main-color')
	export const hover = getContext('shadow-color')
</script>

<div use:styles={{ color, hover }} class="text"><slot /></div>

<style>
	.text {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: 'Caveat', sans-serif;
		font-weight: normal;
		color: var(--color);
		text-align: center;
		cursor: pointer;
		user-select: none;
	}
	.text:hover {
		color: var(--hover);
	}

	@media screen and (orientation: portrait) {
		.text {
			width: 100vw;
			height: 4vh;
			font-size: clamp(4vw, 6vw, 10vw);
		}
	}

	@media screen and (orientation: landscape) {
		.text {
			width: 100vw;
			height: calc((40 / 5) * 2vh);
			font-size: clamp(4vw, 6vw, 8vw);
		}
	}
</style>
