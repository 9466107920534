<!-- container block 5 GETRAENKE -->
<script>
	import { slide } from 'svelte/transition'
	import { open, menu, contact, location } from '../library/scripts/store.js'
	import Menu from '../componentParts/Menu.svelte'
	import LinkContent from '../componentParts/LinkContent.svelte'

	let visible

	menu.subscribe((value) => {
		visible = value
	})

	function openMenu() {
		visible = visible === true ? false : true
		menu.set(visible)
		//reset all open sliders
		open.set(false)
		contact.set(false)
		location.set(false)
	}
</script>

<div on:click={openMenu}><LinkContent>Getränkekarte</LinkContent></div>
{#if visible}
	<div class="container" transition:slide={{ duration: 1000 }}><Menu /></div>
{/if}

<style>
	.container {
		display: flex;
		flex-direction: column;
		width: 100vw;
		padding: 2%;
		cursor: default;
		user-select: none;
	}
</style>
