import { writable } from 'svelte/store'

export let visible = writable(true)
export let login = writable(false)
export let admin = writable(false)
export let open = writable(false)
export let menu = writable(false)
export let contact = writable(false)
export let location = writable(false)

//image mail
export let startSendPhp = writable(false)
export let endSendPhp = writable(false)
