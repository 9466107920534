<script>
	import { getContext } from 'svelte'
	import { slide } from 'svelte/transition'
	import {
		adminSingleReceiver,
		adminSingleReceiverValue,
		adminReceiver,
		check_admin_mail_entries,
		adminArchiveMail,
	} from './AdminModule.svelte'
	import { get_today_full_year } from '../library/scripts/dates.js'
	import { v4 as uuidv4 } from 'uuid'
	import { validate_mail } from '../library/scripts/validation.js'
	import { styles } from '../library/scripts/styles'
	import { urlMail } from '../library/Setup.svelte'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	//receiver (list = admin,single,all)
	let receiver = null

	//show or hide shader of mail-form
	let showShader = false
	let showMessage = false
	let message = ''
	let success = false
	let error = false

	//show or hide spinner while sending form
	let showSpinner = false

	adminReceiver.subscribe((value) => {
		receiver = value
	})

	//the mailinput if single mail is selected
	let singleReceiver = false
	let singleMailValue = null

	//subscribe the values to update page
	adminSingleReceiver.subscribe((value) => {
		singleReceiver = value
	})

	adminSingleReceiverValue.subscribe((value) => {
		singleMailValue = value
	})

	//the mail input and text fields
	let salutationValue = null
	let messageValue = null
	let subjectValue = null

	//select receiver (admin, single or all)
	const select_receiver = (receiver) => {
		adminReceiver.set(receiver)

		//single receiver -> set single receiver to true (shows email inputfield)
		if (receiver === 'single') {
			singleReceiver = true
			return
		}

		//reset inputfield if not single
		singleReceiver = false
	}

	//check all values before we send
	const send_mail = async () => {
		//set the form out of focus
		const form = document.querySelector('form')
		form.style.filter = 'blur(2px)'
		//add the shader
		showShader = true

		//** input validation **//

		//create the object to be tested
		const testObject = {
			subject: subjectValue,
			salutation: salutationValue,
			message: messageValue,
		}

		//if its a mail to single address we test the value of the mail
		if (singleReceiver) {
			const validMail = validate_mail(singleMailValue)
			if (!validMail) {
				error = true
				message = 'Keine gültige Emailadresse'
				setTimeout(() => {
					reset_all('single')
				}, 1000)
				return
			}
		}

		const checkValues = check_admin_mail_entries(testObject)
		if (checkValues !== true) {
			error = true
			message = checkValues
			setTimeout(() => {
				reset_all('admin')
			}, 1000)
			return
		}

		//** END VALIDATION **/

		//all ok we send the mail
		const result = await send_mail_to_server()

		//test mail
		if (result === 'admin') {
			showSpinner = false
			success = true
			showMessage = true
			message = 'test nachricht gesendet'
			setTimeout(() => {
				reset_all('admin')
			}, 1000)
			return
		}

		//regular mail
		if (result === true) {
			showSpinner = false
			success = true
			showMessage = true
			message = 'Nachricht wurde versendet'

			setTimeout(() => {
				reset_all('all')
			}, 1500)
		}
	}

	const send_mail_to_server = async () => {
		//create a unique id for each mail
		const mailId = uuidv4()
		//show the spinner
		showSpinner = true

		//get the receiver from form (selected with radio buttons)
		const sendReceiver = document.querySelector('#receiver').value
		const adminSendMail = await fetch(urlMail, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				cmd: 'admin_send_mail',
				sendReceiver: sendReceiver,
				mailId: mailId,
				mail: singleMailValue,
				subject: subjectValue,
				salutation: salutationValue,
				message: messageValue,
			}),
		})

		const response = await adminSendMail.json()

		//add the message to the session storage if response is ok and the receiver is not the admin (testmail must not be added)
		if (response.response && receiver !== 'admin') {
			let msgArray = JSON.parse(sessionStorage.getItem('mail_archive'))
			//create the date
			const date = get_today_full_year()

			//set the mail dummy if the sendCommand === all
			if (receiver === 'all') singleMailValue = 'ALL'

			const newObject = {
				id: mailId,
				date: date,
				subject: subjectValue,
				mail: singleMailValue,
			}

			msgArray.unshift(newObject)
			adminArchiveMail.set(JSON.stringify(msgArray))
			sessionStorage.setItem('mail_archive', JSON.stringify(msgArray))
		}
		return response.response
	}

	const reset_all = (cmd) => {
		const form = document.querySelector('form')
		form.style.filter = 'blur(0px)'
		adminSingleReceiverValue.set(null)
		showShader = false
		showSpinner = false
		showMessage = false
		error = false
		success = false
		message = ''

		if (cmd !== 'admin') {
			showMessage = false
			subjectValue = null
		}

		if (cmd === 'all') {
			adminSingleReceiver.set(false)
			adminReceiver.set('admin')
			salutationValue = null
			messageValue = null
			singleMailValue = null
			singleReceiver = false
		}
	}
</script>

<div class="container">
	<div class="title">Email erstellen</div>
	{#if !singleMailValue}
		<ul>
			<li on:change|preventDefault={() => select_receiver('admin')} class="radio_button">
				<input type="radio" name="sendmail" checked="checked" id="test_mail" /><span
					use:styles={{ fontS }}
					class="margin_left">Test</span
				>
			</li>

			<li on:change|preventDefault={() => select_receiver('all')} class="radio_button">
				<input type="radio" name="sendmail" id="all_mail" /><span use:styles={{ fontS }} class="margin_left"
					>An Alle</span
				>
			</li>

			<li on:change|preventDefault={() => select_receiver('single')} class="radio_button">
				<input type="radio" name="sendmail" id="single_mail" /><span use:styles={{ fontS }} class="margin_left"
					>Single</span
				>
			</li>
		</ul>
	{/if}

	<!-- the shader of the form when it's sended -->
	{#if showShader}
		<div class="shader">
			<!-- messages to the admin -->
			{#if error}
				<div use:styles={{ fontM }} class="messages error">{message}</div>
			{/if}
			{#if success}
				<div use:styles={{ fontM }} class="messages success">{message}</div>
			{/if}

			{#if showSpinner}
				<div use:styles={{ fontS }} class="spinner" />
				<div use:styles={{ fontS }} class="spinner-text">senden</div>
			{/if}
		</div>
	{/if}

	<form on:submit|preventDefault={send_mail}>
		<!-- input mail for single mail -->
		<input type="hidden" value={receiver} id="receiver" />
		{#if singleReceiver}
			<input
				transition:slide={{ duration: 2000 }}
				use:styles={{ fontM, color, shadow }}
				type="text"
				placeholder="email adresse"
				bind:value={singleMailValue}
				class="form_input"
			/>
		{/if}
		<input
			use:styles={{ fontM, color, shadow }}
			type="text"
			placeholder="subject"
			bind:value={subjectValue}
			class="form_input"
		/>
		<input
			use:styles={{ fontM, color, shadow }}
			type="text"
			placeholder="anrede"
			bind:value={salutationValue}
			class="form_input"
		/>
		<textarea
			use:styles={{ fontM, color, shadow }}
			placeholder="nachricht verfassen"
			name="message"
			rows="10"
			bind:value={messageValue}
			class="form_textarea"
		/>
		<div class="button_container">
			<button use:styles={{ fontS, shadow }} type="button" on:click|preventDefault={reset_all}>reset</button>
			<button use:styles={{ fontS, shadow }} type="sumbmit">senden</button>
		</div>
	</form>
</div>

<style>
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes load {
		0% {
			content: '. ';
		}

		25% {
			content: '..';
		}
		50% {
			content: '...';
		}
		75% {
			content: '....';
		}
		100% {
			content: '.....';
		}
	}
	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 3%;
	}

	.shader {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		height: 100%;
		width: 80%;
		z-index: 1;
	}

	.messages {
		font-size: var(--fontM);
		z-index: 2;
		user-select: none;
	}

	ul {
		display: flex;
		justify-content: space-around;
		list-style: none;
		margin: 2% 0 2% 0;
		padding: 0;
		width: 100%;
	}

	li {
		margin-right: 2%;
		justify-self: center;
	}

	.margin_left {
		margin-left: 2%;
		font-size: var(--fontS);
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2%;
		width: 80%;
		height: 100%;
		opacity: 1;
		filter: blur(0px);
	}

	.form_input,
	.form_textarea {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 100%;
		resize: none;
	}

	.form_input:focus,
	.form_textarea:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	button {
		align-self: flex-end;
		margin-left: 3%;
		border-radius: 5px;
		font-size: var(--fontS);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.radio_button {
		display: flex;
		font-size: 1rem;
		width: 30%;
		user-select: none;
	}

	.button_container {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}

	.success {
		color: green;
	}

	.error {
		color: red;
	}

	.spinner {
		border: solid 10px hsl(0, 0%, 60%);
		border-bottom-color: hsl(224, 88%, 53%);
		border-radius: 50%;
		height: 5vw;
		width: 5vw;
		animation: spin 1.5s linear infinite;
	}

	.spinner-text {
		display: flex;
		justify-content: flex-start;
		width: 20%;
		margin-top: 5%;
		font-size: var(--fontM);
	}

	.spinner-text::after {
		content: '';
		animation: load 1s linear infinite;
	}
</style>
