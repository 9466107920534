<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	export const color = getContext('font-main-color')
</script>

<div use:styles={{ color }} class="text"><slot /></div>

<style>
	.text {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		top: 100%;
		font-family: 'Montserrat', sans-serif;
		font-weight: normal;
		font-size: clamp(0.3rem, 2vh, 2.5vh);
		color: var(--color);
		margin-top: 5vh;
		text-align: center;
		cursor: default;
		user-select: none;
	}
</style>
