<script>
	import { setContext } from 'svelte'
	import { login, admin } from './library/scripts/store.js'

	import Eupopup from './library/Eupopup.svelte'
	import Footer from './library/Footer.svelte'
	import Login from './library/forms/Login.svelte'
	import Shader from './componentParts/Shader.svelte'
	import Home from './Home.svelte'
	import Admin from './Admin.svelte'

	//show / hide login
	let showLogin

	login.subscribe((value) => {
		showLogin = value
	})

	//show admin page
	let showAdmin

	admin.subscribe((value) => {
		showAdmin = value
	})

	//create some global variables
	setContext('font-X', 'clamp(1rem, 5vw, 3rem)')
	setContext('font-XS', 'clamp(1rem, 4vw, 2rem)')
	setContext('font-M', 'clamp(.8rem, 3vw, 1.5rem)')
	setContext('font-S', 'clamp(.5rem, 1.5vw, 1rem)')
	setContext('font-SX', 'clamp(.5rem, 1vw, .8rem)')
	setContext('font-main-color', 'hsl(0, 0%, 60%)')
	setContext('error-color', 'red')
	setContext('shadow-color', 'hsla(226, 68%, 48%, 0.5)')

	const open_login = () => {
		login.set(true)
	}
	const close_login = () => {
		admin.set(false)
	}
</script>

{#if !showAdmin}
	<nav>
		<div class="login" on:click={open_login}>login</div>
	</nav>
	{#if showLogin}
		<Login />
		<Shader />
	{/if}
	<Home />
{/if}

{#if showAdmin}
	<nav>
		<div class="login" on:click={close_login}>logout</div>
	</nav>
	<Admin />
{/if}
<Eupopup />

<Footer>Design by Kybo-Tech 2021</Footer>

<style>
	nav {
		position: absolute;
		display: flex;
		justify-content: flex-end;
		padding-right: 5vw;
		width: 100%;
	}

	.login {
		font-size: clamp(0.8rem, 3vw, 1.5rem);
		color: hsl(0, 0%, 60%);
		cursor: pointer;
		user-select: none;
	}
</style>
