<!-- container block 6 -->
<script>
	import { slide } from 'svelte/transition'
	import { open, menu, contact, location } from '../library/scripts/store.js'
	import TwoCols from '../library/TwoCols.svelte'
	import Map from '../library/google_map/Map.svelte'
	import Frame from '../componentParts/Frame.svelte'
	import Direction from '../componentParts/Direction.svelte'
	import LinkContent from '../componentParts/LinkContent.svelte'

	let visible

	location.subscribe((value) => {
		visible = value
	})

	const openContent = () => {
		visible = visible === true ? false : true
		location.set(visible)
		//reset open slides
		open.set(false)
		menu.set(false)
		contact.set(false)
	}
</script>

<div on:click={openContent}><LinkContent>Wie Du uns findest</LinkContent></div>
{#if visible}
	<div transition:slide={{ duration: 1000 }}>
		<TwoCols>
			<div slot="leftPart"><Frame frame_padding="0%"><Map /></Frame></div>
			<div slot="rightPart"><Direction /></div>
		</TwoCols>
	</div>
{/if}
