<!-- left block 2 -->
<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	import RegisterMail from '../library/forms/RegisterMail.svelte'
	export const font_x = getContext('font-X')
	export const font_xs = getContext('font-XS')
	export const font_m = getContext('font-M')
	export const font_s = getContext('font-S')
	export const font_main_color = getContext('font-main-color')
</script>

<div class="container">
	<div use:styles={{ font_x, font_main_color }} class="text" data-title>BC Lorze Stübli</div>
	<div use:styles={{ font_m, font_main_color }} class="text" data-content>Lorzendamm 30</div>
	<div use:styles={{ font_m, font_main_color }} class="text" data-content>CH - 6340 Baar</div>
	<div use:styles={{ font_m, font_main_color }} class="text" data-margin-top>+41 41 760 20 52</div>
	<div use:styles={{ font_m, font_main_color }} class="text" data-margin-bottom>info@lorzestuebli.ch</div>
</div>

<div class="sub_container">
	<RegisterMail>
		<span slot="register">Ich möchte über Anlässe informiert werden</span>
	</RegisterMail>
</div>

<style>
	.container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		cursor: default;
		user-select: none;
		cursor: default;
	}

	.text {
		font-size: var(--font_m);
		color: var(--font_main_color);
		user-select: none;
	}

	.text[data-title] {
		font-size: var(--font_x);
		color: var(--font_main_color);
		font-weight: bold;
	}

	.text[data-content] {
		font-size: var(--font_m);
		color: var(--font_main_color);
		font-weight: normal;
	}

	.text[data-margin-top] {
		margin-top: 5%;
	}

	.text[data-margin-bottom] {
		margin-bottom: 5%;
	}
</style>
