<script context="module">
	import { mailer } from './PhpMailer.svelte'
	import { endSendPhp } from '../scripts/store.js'
	import { get_today_full_year } from '../scripts/dates.js'
	import { adminArchiveEvent } from '../../admin/AdminModule.svelte'
	import { v4 as uuidv4 } from 'uuid'

	export async function send_image(cmd, mailObject, width, height, div) {
		const MAXWIDTH = width
		const MAXHEIGHT = height
		const THUMBHEIGHT = 80
		const THUMBWIDTH = 120
		const DIVTOADD = div

		//create id for event
		const eventId = uuidv4()

		//image portrait or landscape
		let imageOrientation

		//image path
		let imagepath = mailObject.image.name

		//the data to send to php
		let formData

		//the data to store in session and store
		let sessionData

		if (mailObject.image !== 'noimage') {
			let reader = new FileReader()
			reader.readAsDataURL(mailObject.image)

			//the image size
			reader.size = mailObject.image.size
			reader.name = mailObject.image.name

			reader.onload = () => {
				let image = new Image()
				image.src = reader.result

				//resize image
				image.onload = async () => {
					const canvasElement = document.createElement('canvas')
					const canvasThumb = document.createElement('canvas')

					//scale the image to its maxwidth
					const scaleFactorWidth = MAXWIDTH / image.width
					const scaleFactorHeight = MAXHEIGHT / image.height

					//the thumbnail image
					const scaleThumbWidth = THUMBWIDTH / image.width
					const scaleThumbHeight = THUMBHEIGHT / image.height

					if (image.width > image.height || image.width === image.height) {
						canvasElement.width = MAXWIDTH
						canvasElement.height = image.height * scaleFactorWidth
						canvasThumb.width = THUMBWIDTH
						canvasThumb.height = image.height * scaleThumbWidth
						imageOrientation = 'landscape'
					}

					if (image.width < image.height) {
						canvasElement.height = MAXHEIGHT
						canvasElement.width = image.width * scaleFactorHeight
						canvasThumb.height = THUMBHEIGHT
						canvasThumb.width = image.width * scaleThumbHeight
						imageOrientation = 'portrait'
					}

					//attach the thumb to dom
					if (cmd === 'thumb') {
						let ctxThumb = canvasElement.getContext('2d')
						ctxThumb.drawImage(image, 0, 0, canvasThumb.width, canvasThumb.height)
						const divAttach = document.querySelector('.' + DIVTOADD + '')
						if (divAttach.hasChildNodes()) {
							divAttach.removeChild(divAttach.firstChild)
						}
						divAttach.appendChild(ctxThumb.canvas)
					}

					if (cmd === 'send') {
						//draw the canvas
						let ctx = canvasElement.getContext('2d')
						ctx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height)
						//get the base64 encoded data uri from the resized image
						let imgEncoded = ctx.canvas.toDataURL('image/png', 1)

						//create the mailObject
						formData = {
							eventId: eventId,
							receiver: mailObject.receiver,
							image: imgEncoded,
							imagepath: imagepath,
							orientation: imageOrientation,
							mail: mailObject.mail,
							title: mailObject.title,
							date: mailObject.date,
							time: mailObject.time,
							location: mailObject.location,
						}

						if (!mailObject.flyer) {
							formData = { ...formData, cmd: 'send_admin_image_mail', message: mailObject.message }
						}

						if (mailObject.flyer) {
							formData = { ...formData, cmd: 'send_admin_flyer', message: 'Flyer' }
						}

						let dataResponse = await mailer('send_image', formData)
						if (dataResponse.response) {
							endSendPhp.set(true)
							if (formData.receiver !== 'admin') {
								let archiveObject = JSON.parse(sessionStorage.getItem('event_archive'))

								//create the entry-date (in db created automatically but not in the form data)
								const date = get_today_full_year()
								sessionData = {
									...sessionData,
									id: formData.eventId,
									receiver: mailObject.receiver,
									entrydate: date,
									imagepath: imagepath,
									mail: mailObject.mail,
									title: mailObject.title,
									date: mailObject.date,
									time: mailObject.time,
									location: mailObject.location,
								}

								if (!mailObject.flyer) {
									sessionData = { ...sessionData, message: mailObject.message }
								}

								if (mailObject.flyer) {
									sessionData = { ...sessionData, message: 'Flyer' }
								}

								archiveObject.unshift(sessionData)

								//the data we safe in session storage

								sessionStorage.setItem('event_archive', JSON.stringify(archiveObject))
								adminArchiveEvent.set(JSON.stringify(archiveObject))
							}
						}
					}
				}
			}
		} else {
			if (cmd === 'send') {
				formData = {
					cmd: 'send_admin_image_mail',
					eventId: eventId,
					receiver: mailObject.receiver,
					image: mailObject.image,
					imagepath: '--',
					orientation: null,
					mail: mailObject.mail,
					title: mailObject.title,
					date: mailObject.date,
					time: mailObject.time,
					location: mailObject.location,
					message: mailObject.message,
				}
				let dataResponse = await mailer('send_image', formData)
				if (dataResponse.response) {
					endSendPhp.set(true)
					if (formData.receiver !== 'admin') {
						let archiveObject = JSON.parse(sessionStorage.getItem('event_archive'))

						//create the entry-date (in db created automatically but not in the form data)
						const date = get_today_full_year()
						sessionData = {
							...sessionData,
							id: formData.eventId,
							receiver: mailObject.receiver,
							entrydate: date,
							imagepath: '--',
							mail: mailObject.mail,
							title: mailObject.title,
							date: mailObject.date,
							time: mailObject.time,
							location: mailObject.location,
							message: mailObject.message,
						}

						archiveObject.unshift(sessionData)
						sessionStorage.setItem('event_archive', JSON.stringify(archiveObject))
						adminArchiveEvent.set(JSON.stringify(archiveObject))
					}
				}
			}
		}
	}
</script>
