<script>
	import { onMount } from 'svelte'
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	import { v4 as uuidv4 } from 'uuid'
	import { urlAdmin } from '../library/Setup.svelte'
	import { adminUpdateMenuEntry, check_values } from './AdminModule.svelte'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	//array that holds all categories
	let categories = []

	//variable to show or hide category input according to selected radio button
	let categoryInputVisible = true

	//if the session storage is empty we have no entries yet
	//hide radio-buttons and category select field
	let menuExist

	onMount(async () => {
		const getCategory = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_categories',
			}),
		})

		const dataResponse = await getCategory.json()
		categories = dataResponse.fetch

		if (dataResponse.fetch != 0) {
			categoryInputVisible = false
			menuExist = true
		}
	})

	//the new element to add
	let addElement = {}

	//values for item entries
	let itemCategory = ''
	let itemId = ''
	let itemName = ''
	let itemQuantity = ''
	let itemPrice = ''
	let itemCurrency = ''

	//store the menu
	let menu = []

	//check for doubled items
	let double = false

	//success and error message
	let error = false
	let success = false

	//message to the user
	let message = ''

	//create the entry and check for errors
	function check_item() {
		//the default currency is CHF. We have to add it manually
		if (itemCurrency === '') itemCurrency = 'CHF'
		//create the element to add
		addElement = {
			category: itemCategory,
			id: itemId,
			name: itemName,
			quantity: itemQuantity,
			price: itemPrice,
			currency: itemCurrency,
		}

		//if all is well we add the item to the storage
		const check = check_values(addElement)
		if (check === true) {
			add_item(addElement)
			return
		}
		error = true
		message = check
	}

	//add element and handle views of the DOM
	const add_item = (item) => {
		//hide inputfield for category
		categoryInputVisible = false
		//add the newly created category to categories if it does not exist
		if (categories.length > 0 && !categories.includes(item.category)) categories = [...categories, item.category]
		if (!categories.length) categories = [item.category]

		//create the unique id for the element
		const uniqueId = uuidv4()
		item.id = uniqueId

		const safeEntry = safe_entry(item)

		//add a message if the record is entered successfully
		if (safeEntry) {
			success = true
			message = 'Eintrag gespeichert'
		}

		//reset variables
		itemCategory = ''
		itemName = ''
		itemQuantity = ''
		itemPrice = ''
	}

	//safe the entry to the session store
	const safe_entry = (item) => {
		//variable to check for doubled entries
		let checkDouble = false
		//the menu does not yet exist, we create it
		if (!menuExist) {
			menu = [item]
			const addedToDb = save_item_to_db(item)
			if (addedToDb) {
				sessionStorage.setItem('menu', JSON.stringify(menu))
				adminUpdateMenuEntry.set(JSON.stringify(menu))
				menuExist = true
				return true
			}
		} else {
			const menuObjects = JSON.parse(sessionStorage.getItem('menu'))
			//clean old menu
			menu.length = 0
			//add objects to menu
			menuObjects.forEach((item) => {
				menu = [...menu, item]
			})
			/*
			 * check if we have a doubled entry (quantity does not count)
			 * the price has only double equals because we changed the type from a string to a number!!
			 * remember === checks for value AND type
			 */
			menu.forEach((entry) => {
				if (entry.category === item.category && entry.name === item.name && entry.price == item.price) {
					double = true
					error = true
					message = 'Eintrag bereits vorhanden'
					checkDouble = true
					return false
				}
			})

			//ther is no duplicate, we add the entry
			if (!checkDouble) {
				//add item to the DB
				const addedToDb = save_item_to_db(item)

				//only add the item to the session storage if it's saved to DB first
				if (addedToDb) {
					menu = [...menu, item]
					sessionStorage.setItem('menu', JSON.stringify(menu))

					//update the preview
					adminUpdateMenuEntry.set(JSON.stringify(menu))
					return true
				}
			}

			return false
		}
	}

	// ******************************** HELPER FUNCTIONS ***********************************//

	//safe entry to db
	const save_item_to_db = async (item) => {
		const saveItem = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'save_entry',
				data: {
					category: item.category,
					id: item.id,
					name: item.name,
					quantity: item.quantity,
					price: item.price,
					currency: item.currency,
				},
			}),
		})

		const dataResponse = await saveItem.json()
		return dataResponse.fetch
	}
	//select if category input or select is visible (radio button selection)
	const selectCategoryInput = () => {
		categoryInputVisible = categoryInputVisible === true ? false : true
	}
	//reset error messages
	const reset_error = () => {
		error = false
		success = false
	}
</script>

<div class="container">
	<div class="title">Menue Eintrag erstellen</div>
	<form>
		{#if menuExist != null}
			<div class="radio">
				{#if !categoryInputVisible}
					<input
						use:styles={{ fontM, color, shadow }}
						on:change={selectCategoryInput}
						type="radio"
						id="sg"
						name="g"
						checked="checked"
					/>
				{:else}
					<input use:styles={{ fontM, color, shadow }} on:change={selectCategoryInput} type="radio" id="sg" name="g" />
				{/if}
				<label for="sg">Bestehende Kategorie</label>
			</div>

			<div class="radio">
				{#if categoryInputVisible}
					<input
						use:styles={{ fontM, color, shadow }}
						on:change={selectCategoryInput}
						type="radio"
						id="ng"
						name="g"
						checked="checked"
					/>
				{:else}
					<input use:styles={{ fontM, color, shadow }} on:change={selectCategoryInput} type="radio" id="ng" name="g" />
				{/if}
				<label for="ng">Neue Kategorie</label>
			</div>

			{#if !categoryInputVisible}
				<label for="selectGroup">Kategorie wählen</label>
				<select use:styles={{ shadow, fontM }} id="selectGroup" bind:value={itemCategory}>
					<option value="" selected>--</option>
					{#if categories.length > 0}
						{#each categories as category}
							<option value={category}>{category}</option>
						{/each}
					{/if}
				</select>
			{/if}
		{/if}

		{#if !menuExist || categoryInputVisible}
			<label for="category">Kategorie</label>
			<input
				use:styles={{ fontM, color, shadow }}
				on:focus={reset_error}
				type="text"
				id="category"
				maxlength="32"
				bind:value={itemCategory}
			/>
		{/if}

		<label for="item">Name</label>
		<input
			use:styles={{ fontM, color, shadow }}
			input
			on:focus={reset_error}
			type="text"
			id="item"
			maxlength="25"
			bind:value={itemName}
		/>
		<label for="size">Menge</label>
		<input
			use:styles={{ fontM, color, shadow }}
			on:focus={reset_error}
			type="text"
			id="size"
			maxlength="6"
			bind:value={itemQuantity}
		/>
		<label for="price">Preis</label>
		<input
			use:styles={{ fontM, color, shadow }}
			on:focus={reset_error}
			type="text"
			id="price"
			maxlength="6"
			bind:value={itemPrice}
		/>
		<label for="currency">Währung</label>
		<select use:styles={{ shadow, fontM }} id="currency" bind:value={itemCurrency}>
			<option value="CHF" selected>CHF</option>
			<option value="EU">EU</option>
			<option value="USD">USD</option>
			<option value="YEN">YEN</option>
		</select>
		<button use:styles={{ fontS, shadow }} on:click|preventDefault={check_item}>sichern</button>

		<!-- error and other messages -->
		{#if error}
			<div class="message error">{message}</div>
		{/if}
		{#if success}
			<div class="message success">{message}</div>
		{/if}
	</form>
</div>

<style>
	.container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 65vh;
		cursor: default;
		user-select: none;
	}

	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 3%;
	}

	form {
		display: grid;
		grid-template-columns: 50% 50%;
		width: 90%;
		font-size: clamp(0.8rem, 1.1vw, 1.2rem);
	}

	label {
		display: flex;
		align-items: center;
	}

	input[type='text'] {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 100%;
	}

	input[type='text']:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	select {
		background-color: hsl(0, 0%, 90%);
		box-shadow: 3px 3px 3px var(--shadow);
	}

	button {
		width: 20%;
		grid-column-start: 1;
		grid-column-end: 3;
		justify-self: flex-end;
		margin-right: 0%;
		border-radius: 5px;
		font-size: var(--fontS);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	button:hover {
		background-color: hsl(216, 54%, 71%);
	}

	.radio {
		display: grid;
		grid-template-columns: 20% 80%;
		align-items: start;
		margin-bottom: 10%;
	}

	.message {
		grid-column-start: 1;
		grid-column-end: 3;
		justify-self: center;
		font-size: clamp(0.8rem, 1.1vw, 1.2rem);
	}

	.success {
		color: green;
	}

	.error {
		color: red;
	}
</style>
