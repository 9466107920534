<script>
	import { setCookie, checkCookie } from './scripts/cookie.js'

	let euvisible = true
	let chkCookie = checkCookie('eupop')

	if (chkCookie) euvisible = false

	const hide_eupopup = () => {
		const stCookie = setCookie('eupop', '/', 100)
		euvisible = false
		return
	}
</script>

{#if euvisible}
	<div class="eu_container">
		<div class="pop_text">
			Wir verwenden Cookies und andere Technologien für die einwandfreie Darstellung dieser Seite.<br />
			Mit dem Click auf "OK" stimmst Du deren Verwendung zu.
		</div>
		<button on:click|preventDefault={hide_eupopup}>OK</button>
	</div>
{/if}

<style>
	.eu_container {
		position: fixed;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 15vh;
		padding: 2%;
		width: 100vw;
		bottom: 0px;
		background-color: darkslategrey;
	}

	.pop_text {
		font-size: clamp(0.8rem, 2vw, 1.5rem);
		color: hsl(0, 0%, 70%);
		user-select: none;
	}

	button {
		margin: 0 4% 0 4%;
		font-size: clamp(0.8rem, 2vw, 1.5rem);
		border-radius: 5px;
		border-color: white;
		background-color: indigo;
		color: white;
		width: clamp(100px, 5vw, 150px);
		cursor: pointer;
	}
	button:hover {
		background-color: darkslateblue;
	}
</style>
