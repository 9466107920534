<!-- container block 6 KONTAKT -->
<script>
	import { slide } from 'svelte/transition'
	import { open, menu, contact, location } from '../library/scripts/store.js'
	import ContactForm from '../library/forms/ContactForm.svelte'
	import TwoCols from '../library/TwoCols.svelte'
	import LinkContent from '../componentParts/LinkContent.svelte'
	import Address from '../componentParts/Address.svelte'

	let visible

	contact.subscribe((value) => {
		visible = value
	})

	function openComposite() {
		contact.subscribe((value) => {
			visible = value
		})
		visible = visible === false ? true : false
		contact.set(visible)
		//reset all open sliders
		open.set(false)
		menu.set(false)
		location.set(false)
	}
</script>

<div on:click={openComposite}><LinkContent>Kontakt</LinkContent></div>
{#if visible}
	<div transition:slide={{ duration: 1000 }}>
		<TwoCols>
			<div slot="leftPart"><ContactForm /></div>
			<div slot="rightPart"><Address /></div>
		</TwoCols>
	</div>
{/if}
