<script>
	import { slide } from 'svelte/transition'
	import { adminMenu, adminMail, adminEvent, adminGallery } from './AdminModule.svelte'

	let isHamburgerMenu = false
	let menuLink
	let mailLink
	let eventLink
	let galleryLink

	adminMenu.subscribe((value) => {
		menuLink = value
	})

	adminMail.subscribe((value) => {
		mailLink = value
	})

	adminEvent.subscribe((value) => {
		eventLink = value
	})

	adminGallery.subscribe((value) => {
		galleryLink = value
	})

	const open_link = (link) => {
		if (isHamburgerMenu) {
			const slideMenu = document.querySelector('.menu')
			let menuHeight = slideMenu.style.height
			let mHeight = menuHeight === '0px' ? '20vh' : '0px'
			slideMenu.style.height = mHeight
			slideMenu.style.transition = 'height 1s linear'
		}

		switch (link) {
			case 'menu':
				adminMenu.set(true)
				adminMail.set(false)
				adminEvent.set(false)
				adminGallery.set(false)

				return

			case 'gallery':
				adminMenu.set(false)
				adminGallery.set(true)
				adminEvent.set(false)
				adminMail.set(false)
				return

			case 'mail':
				adminMenu.set(false)
				adminGallery.set(false)
				adminEvent.set(false)
				adminMail.set(true)
				return

			case 'event':
				adminMenu.set(false)
				adminGallery.set(false)
				adminEvent.set(true)
				adminMail.set(false)
				return
		}
	}

	const show_hamburger_menu = () => {
		//set the isHamburgermenu to true
		isHamburgerMenu = true
		const slideMenu = document.querySelector('.menu')
		let menuHeight = slideMenu.style.height
		//first it comes from the menu not from @media
		if (!menuHeight) menuHeight = '0px'
		let mHeight = menuHeight === '0px' ? '20vh' : '0px'
		slideMenu.style.height = mHeight
		slideMenu.style.transition = 'height 1s linear'
	}
</script>

<nav>
	<div on:click={show_hamburger_menu} class="hamburger" />
	<div class="menu">
		{#if menuLink}
			<div class="linkSelected">Karte Management</div>
		{:else}
			<div on:click={() => open_link('menu')} class="link">Karte Management</div>
		{/if}

		{#if mailLink}
			<div class="linkSelected">Mail Management</div>
		{:else}
			<div on:click={() => open_link('mail')} class="link">Mail Management</div>
		{/if}

		{#if eventLink}
			<div class="linkSelected">Event Management</div>
		{:else}
			<div on:click={() => open_link('event')} class="link">Event Management</div>
		{/if}

		{#if galleryLink}
			<div class="linkSelected">GalleryManagement</div>
		{:else}
			<div on:click={() => open_link('gallery')} class="link">Gallery Management</div>
		{/if}
	</div>
</nav>

<style>
	nav {
		position: relative;
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: clamp(20px, 5vh, 50px);
		background-color: darkgray;
	}

	.menu {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
	}

	.hamburger {
		width: clamp(20px, 3vw, 50px);
		height: clamp(20px, 3vw, 50px);
		background-image: url('/images/hamburger.png');
		background-size: cover;
		margin: 2% 5% 2% 0;
		cursor: pointer;
		visibility: hidden;
	}

	.link {
		font-size: clamp(0.8rem, 1.5vw, 2rem);
		cursor: pointer;
	}

	.link:hover {
		color: blue;
	}

	.linkSelected {
		font-size: clamp(0.8rem, 1.5vw, 2rem);
		color: white;
		cursor: default;
		user-select: none;
	}

	@media screen and (max-width: 580px) {
		nav {
			height: auto;
			align-items: flex-end;
			flex-direction: column;
		}
		.menu {
			flex-direction: column;
			align-items: flex-end;
			margin-right: 5%;
			height: 0px;
			overflow: hidden;
		}

		.link {
			font-size: clamp(0.8rem, 2vw, 1rem);
		}

		.hamburger {
			visibility: visible;
		}
	}
</style>
