let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function get_today_full_year() {
	const date = new Date()
	const today = {
		dd: date.getDate(),
		mm: months[date.getMonth()],
		YY: date.getFullYear(),
	}

	const dateString = today.dd + '-' + today.mm + '-' + today.YY
	return dateString
}
