<script>
	//import the variables for mail from setUp
	import { urlAdmin } from '../../library/Setup.svelte'
	import { login, admin } from '../scripts/store.js'

	//input variables
	let mail
	let password

	//error handling
	const mailFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
	let mailError = false
	let pwdError = false

	//error handling with response from server
	let noEmail = false
	let noPwd = false

	//the form data
	let formData = {}

	const close_login = () => {
		login.set(false)
	}

	const submit_form = () => {
		//client-side validation
		if (!mail || !mail.match(mailFormat)) {
			mailError = true
			setTimeout(() => {
				mailError = false
			}, 1000)
			return
		}
		if (!password) {
			pwdError = true
			setTimeout(() => {
				pwdError = false
			}, 1000)
			return
		}

		//all ok we send the form
		formData = { ...formData, mail: mail, pwd: password }

		send_data(formData)
	}

	const send_data = async (formData) => {
		const response = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'login',
				mail: formData.mail,
				pwd: formData.pwd,
			}),
		})
		const dataResponse = await response.json()

		//error handling
		if (dataResponse.response === 'noEmail') {
			noEmail = true
			setTimeout(() => {
				noEmail = false
			}, 1000)
			return
		}
		if (!dataResponse.response) {
			noPwd = true
			setTimeout(() => {
				noPwd = false
			}, 1000)
			return
		}

		login.set(false)
		admin.set(true)
	}

	const show_password = () => {
		const s = document.querySelector('#pwd')
		s.type = s.type === 'password' ? 'text' : 'password'
	}
</script>

<div class="login">
	<div class="closeContainer">
		<div class="closeButton" on:click={close_login}>X</div>
	</div>
	<div class="title">Login</div>
	<form on:submit|preventDefault={submit_form}>
		<input type="text" placeholder="email adresse" name="mail" bind:value={mail} />
		<input
			on:contextmenu|preventDefault={show_password}
			id="pwd"
			type="password"
			placeholder="passwort"
			bind:value={password}
		/>
		<button type="submit">login</button>
	</form>
	{#if mailError}
		<div class="error">Fehler: keine gültige email adresse</div>
	{/if}
	{#if pwdError}
		<div class="error">Fehler: kein passwort</div>
	{/if}

	{#if noEmail}
		<div class="error">Fehler: email adresse existiert nicht</div>
	{/if}
	{#if noPwd}
		<div class="error">Fehler: falsches passwort</div>
	{/if}
</div>

<style>
	.error {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 5%;
		color: red;
	}
	.login {
		position: fixed;
		display: flex;
		flex-direction: column;
		width: 30vw;
		height: 50vh;
		background: black;
		border: 1px solid blue;
		border-radius: 10px;
		margin-left: 50%;
		margin-right: 50%;
		transform: translate(-50%, 50%);
		z-index: 101;
	}

	.closeContainer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		height: 8%;
	}

	.closeButton {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 10%;
		height: 100%;
		border-radius: 2px;
		color: white;
		margin: 5px;
		background: red;
		cursor: pointer;
		user-select: none;
	}

	.title {
		display: flex;
		justify-content: center;
		font-size: clamp(1rem, 4vw, 2rem);
		color: hsl(0, 0%, 60%);
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15%;
	}

	button {
		align-self: flex-end;
		margin-right: 15%;
		border-radius: 5px;
		font-size: clamp(1em, 30%, 2rem);
		cursor: pointer;
		background-color: darkgrey;
	}

	button:hover {
		background-color: rgb(199, 197, 197);
	}

	input {
		font-size: clamp(1em, 30%, 2rem);
		border-color: blue;
		width: 60%;
		background-color: darkgrey;
	}

	input:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}
</style>
