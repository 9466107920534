<!-- left block 2 -->
<script>
	import { styles } from '../library/scripts/styles.js'
	import { getContext } from 'svelte'
	export const font_x = getContext('font-X')
	export const font_xs = getContext('font-XS')
	export const font_m = getContext('font-M')
	export const font_s = getContext('font-S')
	export const font_main_color = getContext('font-main-color')
</script>

<div use:styles={{ font_main_color: font_main_color }} class="gridlayout">
	<div use:styles={{ font_m }} class="text">
		Dienstag <span use:styles={{ font_s }} class="text" data-font-small>(Training ab 19:00)</span>
	</div>
	<div use:styles={{ font_m }} class="text">ab</div>
	<div use:styles={{ font_m }} class="text">18:00</div>
	<div use:styles={{ font_m }} class="text">
		Donnerstag <span use:styles={{ font_s }} class="text" data-font-small>(Training ab 19:00)</span>
	</div>
	<div use:styles={{ font_m }} class="text">ab</div>
	<div use:styles={{ font_m }} class="text">18:00</div>
	<div use:styles={{ font_m }} class="text">Freitag</div>
	<div use:styles={{ font_m }} class="text">ab</div>
	<div use:styles={{ font_m }} class="text">18:00</div>
	<div use:styles={{ font_m }} class="text">
		Samstag <span use:styles={{ font_s }} class="text" data-font-small>(nur im Sommer)</span>
	</div>
	<div use:styles={{ font_m }} class="text">ab</div>
	<div use:styles={{ font_m }} class="text">15:00</div>
	<div use:styles={{ font_m }} class="text">Sonntag</div>
	<div use:styles={{ font_m }} class="text">ab</div>
	<div use:styles={{ font_m }} class="text">11:00</div>
	<div use:styles={{ font_m }} class="text" data-span-center data-margin-top>
		Im Sommer länger / im Winter kürzer<br />Gerne öffne ich auch auf Anfrage
	</div>
</div>

<style>
	.gridlayout {
		display: grid;
		width: 100%;
		grid-template-columns: 70% 0.5fr 1fr;
		color: var(--font_main_color);
		row-gap: 3vh;
		cursor: default;
		user-select: none;
	}
	.text {
		font-size: var(--font_m);
	}

	.text[data-span-center] {
		grid-column: 1/4;
		justify-self: center;
		text-align: center;
	}

	.text[data-font-small] {
		font-size: var(--font_s);
	}

	.text[data-margin-top] {
		margin-top: 5%;
	}

	@media screen and (orientation: portrait) {
		.gridlayout {
			row-gap: 2vw;
		}
	}
</style>
