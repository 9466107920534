<script>
	import { onDestroy } from 'svelte'
	import { adminMenu, adminGallery, adminMail, adminEvent } from './admin/AdminModule.svelte'
	import Title from './admin/Title.svelte'
	import Navigation from './admin/Navigation.svelte'
	import Menu from './admin/Menu.svelte'
	import Mail from './admin/Mail.svelte'
	import Event from './admin/Event.svelte'
	import Gallery from './admin/Gallery.svelte'

	let visibleMenu
	let visibleMail
	let visibleEvent
	let visibleGallery

	adminMenu.subscribe((value) => {
		visibleMenu = value
	})

	adminMail.subscribe((value) => {
		visibleMail = value
	})

	adminEvent.subscribe((value) => {
		visibleEvent = value
	})

	adminGallery.subscribe((value) => {
		visibleGallery = value
	})

	onDestroy(() => {
		sessionStorage.clear('menu')
		sessionStorage.clear('admin_mail')
	})
</script>

<Title />
<Navigation />
{#if visibleMenu}
	<Menu />
{/if}

{#if visibleMail}
	<Mail />
{/if}

{#if visibleEvent}
	<Event />
{/if}

{#if visibleGallery}
	<Gallery />
{/if}
