<script context="module">
	//import the variables for mail from setUp
	import { urlMail } from '../Setup.svelte'

	let sendObject

	export const mailer = async (cmd, formData) => {
		if (cmd === 'send_mail') {
			sendObject = {
				cmd: formData.cmd,
				name: formData.name,
				mail: formData.mail,
				msg: formData.msg,
				recaptcha: formData.recaptcha,
			}
		}

		if (cmd === 'send_image') {
			sendObject = {
				cmd: formData.cmd,
				receiver: formData.receiver,
				sendEvent: formData.sendEvent,
				eventId: formData.eventId,
				image: formData.image,
				imagepath: formData.imagepath,
				orientation: formData.orientation,
				mail: formData.mail,
				title: formData.title,
				date: formData.date,
				time: formData.time,
				location: formData.location,
				msg: formData.message,
				recaptcha: formData.recaptcha,
			}
		}

		try {
			const response = await fetch(urlMail, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(sendObject),
			})

			const dataResponse = await response.json()

			//return the response to the async function of the caller
			return dataResponse
		} catch (e) {
			return { sent: false, code: 'ERROR: es konnte keine Verbindung hergestellt werden' }
		}
	}
</script>
