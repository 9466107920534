<script>
	import { onMount } from 'svelte'
	import { getContext } from 'svelte'
	import { adminReceiver, adminSingleReceiver, adminSingleReceiverValue } from './AdminModule.svelte'
	import { styles } from '../library/scripts/styles'
	import { urlAdmin } from '../library/Setup.svelte'
	import { validate_mail } from '../library/scripts/validation.js'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const fontSX = getContext('font-SX')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	let searchInput = null
	let maillist = []

	//error & success handling
	let error = false
	let success = false
	let message = null

	const check_input = async (cmd) => {
		//no mail
		if (cmd !== 'search') {
			const validMail = validate_mail(searchInput)
			if (searchInput === null || !validMail) {
				error = true
				message = 'Keine oder ungültige Email'
				reset()
				return
			}
		}

		if (cmd === 'add') {
			const update = await update_mails(cmd, searchInput)

			if (!update) {
				error = true
				message = 'Email bereits vorhanden'
				reset()
				return
			}

			if (update) {
				maillist.length = 0
				success = true
				message = 'Email wurde hinzugefügt'
				update.forEach((item) => {
					maillist = [...maillist, item]
				})

				reset()
			}
		}

		if (cmd === 'search') {
			const searchResult = await update_mails(cmd, searchInput)

			if (!searchResult) {
				error = true
				message = 'Kein Eintrag gefunden'
				reset()
				return
			}

			//reset maillist
			maillist.length = 0

			searchResult.forEach((item) => {
				maillist = [...maillist, item]
			})

			return
		}
	}

	//from the mail entries send and delete
	const entry_cmd = async (cmd, email) => {
		if (cmd === 'delete') {
			let check = await update_mails(cmd, email)
			if (check) {
				//reset the maillist
				maillist.length = 0
				success = true
				message = 'Email wurde entfernt'
				check.forEach((item) => {
					maillist = [...maillist, item]
				})
				reset()
			}
		}

		if (cmd === 'send') {
			adminSingleReceiver.set(true)
			adminSingleReceiverValue.set(email)
			adminReceiver.set('single')
			success = true
			message = 'Email erstellt'
			reset()
		}
	}

	const update_mails = async (cmd, email) => {
		let sendCmd = null

		if (cmd === 'add') sendCmd = 'add_admin_email'
		if (cmd === 'delete') sendCmd = 'delete_admin_email'
		if (cmd === 'search') sendCmd = 'search_admin_email'

		const updateMail = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				cmd: sendCmd,
				mail: email,
			}),
		})

		const response = await updateMail.json()
		return response.fetch
	}

	const reset = () => {
		setTimeout(() => {
			error = false
			success = false
			message = null
		}, 2500)
	}

	//reset value of input on focus
	const resetValue = () => {
		searchInput = null
	}

	const reset_search = () => {
		searchInput = null
		get_maillist()
	}

	const get_maillist = async () => {
		const response = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_maillist',
			}),
		})
		const dataResponse = await response.json()
		maillist = dataResponse.fetch
	}

	onMount(async () => {
		get_maillist()
	})
</script>

<div class="container">
	<form>
		<ul>
			<li>
				<input
					use:styles={{ fontM, color, shadow }}
					type="text"
					placeholder="suchen"
					bind:value={searchInput}
					on:focus={resetValue}
				/>
				<button on:click|preventDefault={() => check_input('search')} use:styles={{ shadow, fontSX }} type="sumbmit"
					>suchen</button
				>
				<button on:click|preventDefault={() => check_input('add')} use:styles={{ shadow, fontSX }} type="sumbmit"
					>hinzufügen</button
				>
				<button on:click|preventDefault={reset_search} use:styles={{ shadow, fontSX }} type="sumbmit">reset</button>
			</li>
		</ul>
	</form>
	<div use:styles={{ fontS, color, shadow }} class="mail_list">
		{#each maillist as entry}
			<div use:styles={{ fontM, color }} class="mail_entry">
				<span>{entry}</span>
				<span on:click|preventDefault={() => entry_cmd('send', entry)} use:styles={{ color, fontSX }} class="send" />

				<span
					on:click|preventDefault={() => entry_cmd('delete', entry)}
					use:styles={{ color, fontSX }}
					class="delete"
				/>
			</div>
		{/each}
	</div>

	{#if success}
		<div use:styles={{ fontM }} class="message success">{message}</div>
	{/if}

	{#if error}
		<div use:styles={{ fontM }} class="message error">{message}</div>
	{/if}
</div>

<style>
	.delete {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/bin.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.send {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/mail.png');
		background-repeat: no-repeat;
		background-size: cover;
	}
	.container {
		display: flex;
		flex-direction: column;
		height: 80vh;
	}

	.mail_list {
		display: flex;
		flex-direction: column;
		height: 60%;
		width: 90%;
		padding: 2%;
		border: 1px solid var(--shadow);
		background-color: hsl(0, 0%, 95%);
		box-shadow: 3px 3px 3px var(--shadow);
		overflow-y: auto;
	}

	.mail_entry {
		display: grid;
		grid-template-columns: 80% 10% 10%;
		font-size: var(--fontS);
		color: var(--color);
		margin-bottom: 2%;
		user-select: none;
	}

	form {
		display: flex;
	}

	ul {
		padding: 0;
		width: 100%;
	}

	li {
		list-style: none;
	}

	input {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 60%;
		margin-right: 2%;
	}

	input:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	button {
		align-self: flex-end;
		margin-right: 0%;
		border-radius: 5px;
		font-size: var(--fontSX);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.message {
		display: flex;
		width: 90%;
		font-size: var(--fontM);
		margin-top: 5%;
	}

	.success {
		color: green;
	}

	.error {
		color: red;
	}
</style>
