<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	export const shadow = getContext('shadow-color')
	export let image_path
	export let frame_padding
</script>

<div class="container">
	<div use:styles={{ shadow, frame_padding }} class="frame">
		{#if image_path != null}
			<img src={image_path} alt="img_main" />
		{:else}
			<slot />
		{/if}
	</div>
</div>

<style>
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 2%;
	}

	.frame {
		display: flex;
		justify-content: center;
		background-color: lightgrey;
		border: 1px solid var(--shadow);
		box-shadow: 5px 5px 5px var(--shadow);
		padding: var(--frame_padding);
	}

	img {
		width: 100%;
		object-fit: cover;
	}
</style>
