<script>
	import { onMount } from 'svelte'
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles'
	import { adminArchiveEvent } from '../admin/AdminModule.svelte'
	import { urlAdmin, imagePath } from '../library/Setup.svelte'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const fontSX = getContext('font-SX')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	let eventArchive = []
	let searchInput
	let eventView = false
	let eventObject = []

	const fetch_archive = async () => {
		const response = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_eventarchive',
			}),
		})
		const dataResponse = await response.json()

		if (dataResponse.fetch === 0) {
			adminArchiveEvent.set('[]')
			sessionStorage.setItem('event_archive', '[]')
			eventArchive = dataResponse.fetch
			return
		}

		return dataResponse.fetch
	}

	onMount(async () => {
		eventArchive = await fetch_archive()
		adminArchiveEvent.set(JSON.stringify(eventArchive))
		sessionStorage.setItem('event_archive', JSON.stringify(eventArchive))
		adminArchiveEvent.subscribe((ev) => {
			eventArchive = JSON.parse(ev)
		})
	})

	const read_event = (id) => {
		//get the events from session
		const sessionArchive = JSON.parse(sessionStorage.getItem('event_archive'))
		sessionArchive.forEach((event) => {
			if (event.id === id) {
				eventObject = event
				eventObject.entrydate = split_date(eventObject.entrydate)
				eventView = true
			}
		})
	}

	const delete_event = async (id) => {
		const delEvent = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'delete_event_archive_entry',
				id: id,
			}),
		})

		const dataResponse = await delEvent.json()

		//reset eventArchive
		eventArchive.length = 0
		eventArchive = dataResponse.fetch

		//set the adminEvent
		adminArchiveEvent.set(JSON.stringify(eventArchive))
		sessionStorage.setItem('event_archive', JSON.stringify(eventArchive))

		eventView = false
	}

	const archive_cmd = (cmd, id) => {
		switch (cmd) {
			case 'show_event':
				eventView = true
				read_event(id)
				return

			case 'close_event':
				eventView = false
				return

			case 'delete_event':
				const del = delete_event(id)
				return

			case 'search':
				//get the events from session
				let returnObject = []
				const eventsSearch = JSON.parse(sessionStorage.getItem('event_archive'))
				eventsSearch.forEach((event) => {
					if (event.title.includes(id)) {
						returnObject.push(event)
					}
				})

				//reset eventArchive
				eventArchive.length = 0
				eventArchive = returnObject
				return

			case 'reset':
				//reset event archive
				eventArchive.length = 0

				//get the archive from session
				eventArchive = JSON.parse(sessionStorage.getItem('event_archive'))

				//reset the search field
				searchInput = null
				return
		}
	}

	const split_date = (date) => {
		const dateArray = date.split(' ')
		const datumArray = dateArray[0].split('-')
		const dateString = datumArray[2] + '-' + datumArray[1] + '-' + datumArray[0]
		return dateString
	}
</script>

<div class="container">
	<div class="title">Event Archiv</div>
	<form>
		<ul>
			<li>
				<input use:styles={{ fontM, color, shadow }} type="text" placeholder="event suchen" bind:value={searchInput} />
				<button
					on:click|preventDefault={() => archive_cmd('search', searchInput)}
					use:styles={{ shadow, fontSX }}
					type="sumbmit">suchen</button
				>
				<button
					on:click|preventDefault={() => archive_cmd('reset', searchInput)}
					use:styles={{ shadow, fontSX }}
					type="sumbmit">reset</button
				>
			</li>
		</ul>
	</form>

	<div use:styles={{ fontS, color, shadow }} class="archive_list">
		{#if !eventView}
			{#if eventArchive.length === 0}
				<div use:styles={{ fontM, color }} class="archive_entry error">Noch keine Events</div>
			{:else}
				{#each eventArchive as entry}
					<div use:styles={{ fontM, color }} class="archive_entry">
						<span>{split_date(entry.entrydate)}</span>
						<span on:click={() => archive_cmd('show_event', entry.id)} class="archive_subject">{entry.title}</span>
						<span on:click={() => archive_cmd('delete_event', entry.id)} class="delete" />
					</div>
				{/each}
			{/if}
		{:else}
			<div use:styles={{ fontM, color }} class="archive_entry" />

			<div class="show_event">
				<div use:styles={{ fontS }} class="mail_s mail_select">Gesendet am:</div>
				<div use:styles={{ fontS }} class="mail_s mail_select">{eventObject.entrydate}</div>
				<div use:styles={{ fontS }} class="mail_s mail_select">Empfänger:</div>
				<div use:styles={{ fontS }} class="mail_s">{eventObject.mail}</div>
				<div use:styles={{ fontS }} class="mail_s">Titel:</div>
				<div use:styles={{ fontS }} class="mail_s">{eventObject.title}</div>
				<div use:styles={{ fontS }} class="mail_s">Event Datum:</div>
				<div use:styles={{ fontS }} class="mail_s">{eventObject.date}</div>
				<div use:styles={{ fontS }} class="mail_s">Event Beginn:</div>
				<div use:styles={{ fontS }} class="mail_s">{eventObject.time}</div>

				<div class="mail_divider" />
				<div use:styles={{ fontM }} class="mail_m mail_span">{eventObject.message}</div>
				{#if eventObject.imagepath !== '--'}
					<div class="mail_span mail_center">
						<img src="{imagePath}images/events/{eventObject.imagepath}" alt="eventimg" width="95%" />
					</div>
				{/if}
				<div class="mail_divider" />
				<div class="button_container">
					<div on:click={() => archive_cmd('close_event')} class="close" />
					<div on:click|preventDefault={() => archive_cmd('delete_event', eventObject.id)} class="delete" />
				</div>
			</div>
		{/if}
	</div>
</div>

<style>
	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 3%;
	}
	.show_event {
		display: grid;
		grid-template-columns: 30% 70%;
		row-gap: 4%;
	}

	.mail_s {
		font-size: var(--fontS);
	}

	.mail_m {
		font-size: var(--fontM);
	}

	.mail_span {
		grid-column-start: 1;
		grid-column-end: 3;
		margin-bottom: 3%;
	}

	.mail_center {
		text-align: center;
	}

	.mail_select {
		user-select: none;
	}

	.mail_divider {
		min-height: 5%;
		border-bottom: 1px solid hsl(0, 0%, 60%);
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.container {
		display: flex;
		flex-direction: column;
		height: 80vh;
	}

	.button_container {
		display: flex;
		width: 100%;
		grid-column-start: 1;
		grid-column-end: 3;
		padding-bottom: 5%;
	}

	.archive_list {
		display: flex;
		flex-direction: column;
		height: 60%;
		width: 90%;
		padding: 2%;
		border: 1px solid var(--shadow);
		background-color: hsl(0, 0%, 95%);
		box-shadow: 3px 3px 3px var(--shadow);
		overflow-y: auto;
	}

	.archive_subject {
		cursor: pointer;
	}

	.archive_subject:hover {
		color: blue;
	}

	.archive_entry {
		display: grid;
		grid-template-columns: 30% 50% 10% 10%;
		color: var(--color);
		margin-bottom: 2%;
		user-select: none;
	}

	.archive_entry:hover {
		background-color: hsl(0, 0%, 90%);
	}

	form {
		display: flex;
	}

	ul {
		padding: 0;
		width: 100%;
	}

	li {
		list-style: none;
	}

	input {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 60%;
		margin-right: 2%;
	}

	input:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	button {
		align-self: end;
		margin-right: 4%;
		border-radius: 5px;
		font-size: var(--fontSX);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.delete {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/bin.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.close {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/close.png');
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 4%;
	}

	/* .success {
		color: green;
	} */

	.error {
		color: red;
	}
</style>
