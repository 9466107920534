export function setCookie(cname, cvalue, exdays) {
	const d = new Date()
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
	let expires = 'expires=' + d.toUTCString()
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function getCookie(cname) {
	let name = cname + '='
	let cArray = document.cookie.split(';')
	for (let i = 0; i < cArray.length; i++) {
		let c = cArray[i]
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export function checkCookie(name) {
	let cookie = getCookie(name)
	if (cookie != '') return true
	return false
}
