<script>
	import { onMount } from 'svelte'
	import TwoCols from '../library/TwoCols.svelte'
	import MailContent from './MailContent.svelte'
	import MailSend from './MailSend.svelte'
	import { urlMail } from '../library/Setup.svelte'
	import { adminArchiveMail } from './AdminModule.svelte'

	let archive = []

	const fetch_archive = async () => {
		const response = await fetch(urlMail, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_archive',
			}),
		})
		const dataResponse = await response.json()

		if (dataResponse.fetch === 0) {
			adminArchiveMail.set('[]')
			sessionStorage.setItem('mail_archive', '[]')
			return
		}
		dataResponse.fetch.forEach((item) => {
			let tmpItem = {
				id: item.id,
				date: item.date,
				mail: item.mail,
				subject: item.subject,
			}

			//add the item at the beginning of the array menu
			archive.push(tmpItem)
		})

		adminArchiveMail.set(JSON.stringify(archive))
		sessionStorage.setItem('mail_archive', JSON.stringify(archive))
	}

	onMount(async () => {
		fetch_archive()
	})
</script>

<TwoCols>
	<div slot="leftPart"><MailSend /></div>
	<div slot="rightPart"><MailContent /></div>
</TwoCols>
