<script>
	import { onMount } from 'svelte'
	import { mapload } from './mapStore.js'

	let ready
	let zoom = 14
	const position = { lat: 47.1934, lng: 8.54237 }
	const label = 'BC Lorzestübli'

	//add the callback from google map link to window
	window.initMap = function ready() {
		mapload.set(true)
	}

	onMount(async () => {
		mapload.subscribe((value) => {
			ready = value
			if (ready) {
				const mapContainer = document.querySelector('.map')
				const map = new google.maps.Map(mapContainer, {
					zoom,
					center: position,
				})

				const marker = new google.maps.Marker({
					position: position,
					map: map,
					collisionBehavior: google.maps.CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY,
					label: label,
				})
			}
		})
	})
</script>

<div class="container">
	{#if !ready}
		<h2>waiting for map</h2>
	{/if}
	<div class="map" />
</div>

<svelte:head>
	<script
		defer
		async
		src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCeYI_EcAVic_PrIV2kazAOrLR61M-x9Ws&callback=initMap"></script>
</svelte:head>

<style>
	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: clamp(150px, 45vw, 350px);
		height: clamp(150px, 45vw, 350px);
	}
	.map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
</style>
