<script context="module">
	import { writable } from 'svelte/store'

	//stores for navigation
	export const adminMenu = writable(true)
	export const adminMail = writable(false)
	export const adminEvent = writable(false)
	export const adminGallery = writable(false)

	//store for menu update
	export const adminUpdateMenuEntry = writable(sessionStorage.getItem('menu'))

	//store for mail stuff
	export const adminSingleReceiver = writable(false)
	export const adminSingleReceiverValue = writable(null)

	//default mailCommand
	export const adminReceiver = writable('admin')

	//update the mailArchive
	export const adminArchiveMail = writable(sessionStorage.getItem('admin_mail'))

	//update the eventArchive
	export const adminArchiveEvent = writable(sessionStorage.getItem('event_archive'))

	//functions to export
	export function check_values(values) {
		if (values.category === '') return 'Feld Kategorie ist leer'
		if (values.name === '') return 'Feld Name ist leer'
		if (values.quantity === '') return 'Feld Menge ist leer'
		if (values.price === '') return 'Feld Preis ist leer'
		if (!parseFloat(values.price)) return 'Feld Preis ist keine Zahl'
		return true
	}

	export function check_admin_mail_entries(values) {
		if (values.subject === '' || values.subject === null) return 'Kein Subject'
		if (values.salutation === '' || values.salutation === null) return 'Keine Anrede'
		if (values.message === '' || values.message === null) return 'Keine Nachricht'
		return true
	}
</script>
