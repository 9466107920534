<script context="module">
	//live
	export const urlMail = 'https://www.lorzestuebli.ch/PHP/mailHandler.php'
	export const urlAdmin = 'https://www.lorzestuebli.ch/PHP/adminHandler.php'
	export const recaptcha = '6LeSDfUZAAAAAFnV9m4Uk1RJZubRmWwUtz8QPJhD'
	export const imagePath = '/'

	//dev
	// export const urlMail = 'https://lorzestuebli.test/PHP/mailHandler.php'
	// export const urlAdmin = 'https://lorzestuebli.test/PHP/adminHandler.php'
	// export const recaptcha = '6LdMzeIZAAAAAA-C0HIZwXVz0XCQ1Ty_XjebdOIp'
	// export const imagePath = 'https://lorzestuebli.test/'
</script>
