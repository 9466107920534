<script>
	import { onMount } from 'svelte'
	import { adminUpdateMenuEntry } from './AdminModule.svelte'
	import { urlAdmin } from '../library/Setup.svelte'
	import TwoCols from '../library/TwoCols.svelte'
	import MenuContent from './MenuContent.svelte'
	import MenuEntry from './MenuEntry.svelte'

	//holds the menu objects
	let menu = []

	//load the menu from db
	const fetch_menu_from_db = async () => {
		const fetchMenu = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_menu',
			}),
		})

		const dataResponse = await fetchMenu.json()
		if (dataResponse.fetch === 0) {
			adminUpdateMenuEntry.set('[]')
			sessionStorage.setItem('menu', '[]')
			return
		}
		dataResponse.fetch.forEach((item) => {
			let tmpItem = {
				category: item[0],
				id: item[1],
				name: item[2],
				quantity: item[3],
				price: item[4],
				currency: item[5],
			}

			//add the item at the beginning of the array menu
			menu.unshift(tmpItem)
		})

		adminUpdateMenuEntry.set(JSON.stringify(menu))
		sessionStorage.setItem('menu', JSON.stringify(menu))
	}

	onMount(() => {
		fetch_menu_from_db()
	})
</script>

<TwoCols>
	<div slot="leftPart"><MenuEntry /></div>
	<div slot="rightPart"><MenuContent /></div>
</TwoCols>
