<script>
	import { urlAdmin, recaptcha } from '../Setup.svelte'
	import { validate_mail } from '../scripts/validation.js'
	import { slide } from 'svelte/transition'

	//holds the mail address bound from form
	let email

	//the form data as object
	let formData = {}

	//show hide email input
	let showInput = false

	//messages to the user
	let error = false
	let success = false
	let message = ''

	//pattern for email address
	const mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

	const check_mail = () => {
		const validMail = validate_mail(email)
		if (!validMail) {
			message = 'Keine oder ungültige Emailadresse'
			error = true
			return
		}

		//all ok we send the mail
		let send = get_recaptcha(email)
	}

	//send the email
	const get_recaptcha = (email) => {
		window.grecaptcha.execute(recaptcha, { action: 'maillist' }).then(function (token) {
			formData = { mail: email, recaptcha: token }

			send_mail(formData)

			async function send_mail() {
				const sendMail = await fetch(urlAdmin, {
					method: 'POST',
					headers: { 'Content-type': 'application/json' },
					body: JSON.stringify({
						cmd: 'add_user_email',
						mail: formData.mail,
						recaptcha: formData.recaptcha,
					}),
				})

				const response = await sendMail.json()
				if (response.fetch) {
					success = true
					message = 'Deine Email-Adresse wurde eingetragen'
				}

				if (!response.fetch) {
					error = true
					message = 'Die Email-Adresse besteht bereits'
				}

				setTimeout(() => {
					success = false
					error = false
					message = ''
					showInput = false
				}, 2500)
			}
		})
	}

	const reset_values = () => {
		error = false
		success = false
		message = ''
		email = ''
	}
</script>

<ul>
	<li><input type="checkbox" name="checkbox" id="addmail" bind:checked={showInput} /></li>
	<li><slot name="register" /></li>
</ul>

{#if showInput}
	<ul transition:slide={{ duration: 1000 }}>
		<li><input type="text" name="mail" placeholder="email adresse" bind:value={email} on:focus={reset_values} /></li>
		<li><button on:click|preventDefault={check_mail}>senden</button></li>
	</ul>
{/if}
<!-- messages -->
{#if success}
	<ul>
		<li class="success">{message}</li>
	</ul>
{/if}

{#if error}
	<ul>
		<li class="error">{message}</li>
	</ul>
{/if}

<style>
	ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		color: hsl(0, 0%, 60%);
		font-size: clamp(0.8rem, 1.2vw, 1.8rem);
	}

	li {
		margin-right: 2%;
	}

	input[type='text'] {
		box-shadow: 3px 3px 3px hsla(226, 68%, 48%, 0.5);
		font-size: clamp(0.8rem, 1.2vw, 1.8rem);
		border-color: hsla(226, 68%, 48%, 0.5);
		color: hsl(0, 0%, 60%);
		width: 100%;
	}

	input[type='text']:focus {
		outline: none;
		border-color: green;
		color: hsl(0, 0%, 60%);
	}

	input[type='checkbox'] {
		width: '20px';
	}

	button {
		align-self: flex-end;
		margin-right: 0%;
		border-radius: 5px;
		font-size: clamp(0.8rem, 1.2vw, 1.8rem);
		box-shadow: 3px 3px 3px hsla(226, 68%, 48%, 0.5);
		cursor: pointer;
	}
	button:hover {
		background-color: hsla(226, 68%, 48%, 0.5);
	}

	.success {
		color: green;
	}
	.error {
		color: red;
	}
</style>
