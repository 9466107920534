<script>
	import { getContext } from 'svelte'
	import { adminArchiveMail } from './AdminModule.svelte'
	import { styles } from '../library/scripts/styles'
	import { urlMail } from '../library/Setup.svelte'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const fontSX = getContext('font-SX')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	let searchInput = null

	let archive

	adminArchiveMail.subscribe((value) => {
		archive = JSON.parse(value)
	})

	//error & success handling
	let error = false
	let success = false
	let message = null

	//read mail
	let readMail = false
	let mailObject

	const read_mail = async (id) => {
		const fetchMail = await fetch(urlMail, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				cmd: 'readmail',
				id: id,
			}),
		})

		const response = await fetchMail.json()
		readMail = true
		mailObject = response.fetch
	}

	//close readMail window
	const close_read_mail = () => {
		readMail = false
	}

	//from the mail entries send and delete
	const archive_cmd = async (cmd, id) => {
		if (cmd === 'delete') {
			let check = await archive_update(cmd, id)
			if (check) {
				//reset the archive
				archive.length = 0
				success = true
				message = 'Nachricht wurde entfernt'
				check.forEach((item) => {
					archive = [...archive, item]
				})
				//reset store and session storage
				adminArchiveMail.set(JSON.stringify(archive))
				sessionStorage.setItem('mail_archive', JSON.stringify(archive))
				reset()
			}
		}

		if (cmd === 'search') {
			let check = await archive_update(cmd, id)
			if (check) {
				//reset the archive
				archive.length = 0
				success = true
				check.forEach((item) => {
					archive = [...archive, item]
				})
				reset()
				return
			}

			error = true
			message = 'Kein Subject gefunden'
			reset()
		}
	}

	const archive_update = async (cmd, id) => {
		//note: in search the id === searchString
		let sendCmd = null
		if (cmd === 'delete') sendCmd = 'delete_archive_entry'
		if (cmd === 'search') sendCmd = 'search_archive_entry'

		const updateMail = await fetch(urlMail, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				cmd: sendCmd,
				id: id,
			}),
		})

		const response = await updateMail.json()
		//update session storage
		if (sendCmd === 'delete_archive_entry') {
			sessionStorage.setItem('mail_archive', JSON.stringify(response.fetch))
			return response.fetch
		}
		return response.fetch
	}

	const reset = () => {
		setTimeout(() => {
			error = false
			success = false
			message = null
			readMail = false
		}, 2500)
	}

	//reset value of input on focus
	const resetValue = () => {
		searchInput = null
	}

	const reset_search = () => {
		searchInput = null
		//update list
		archive.length = 0
		adminArchiveMail.set(sessionStorage.getItem('mail_archive'))
		archive = JSON.parse(sessionStorage.getItem('mail_archive'))
	}
</script>

<div class="container">
	<form>
		<ul>
			<li>
				<input
					use:styles={{ fontM, color, shadow }}
					type="text"
					placeholder="subject suchen"
					bind:value={searchInput}
					on:focus={resetValue}
				/>
				<button
					on:click|preventDefault={() => archive_cmd('search', searchInput)}
					use:styles={{ shadow, fontSX }}
					type="sumbmit">suchen</button
				>
				<button on:click|preventDefault={reset_search} use:styles={{ shadow, fontSX }} type="sumbmit">reset</button>
			</li>
		</ul>
	</form>
	<div use:styles={{ fontS, color, shadow }} class="archive_list">
		{#if !readMail}
			{#each archive as entry}
				<div use:styles={{ fontM, color }} class="archive_entry">
					<span on:click|preventDefault={() => read_mail(entry.id)} use:styles={{ fontS }} class="archive_subject"
						>{entry.subject}</span
					>
					<span use:styles={{ fontSX }} class="archive_entry_date">{entry.date}</span>
					<span use:styles={{ fontSX }} class="archive_entry_options">{entry.mail}</span>
					<span
						on:click|preventDefault={() => archive_cmd('delete', entry.id)}
						use:styles={{ color, fontSX }}
						class="delete"
					/>
				</div>
			{/each}
		{:else}
			<div class="read_mail">
				<div use:styles={{ fontS }} class="mail_s mail_select">Gesendet am:</div>
				<div use:styles={{ fontS }} class="mail_s mail_select">{mailObject.date}</div>
				<div use:styles={{ fontS }} class="mail_s mail_select">Empfänger:</div>
				<div use:styles={{ fontS }} class="mail_s">{mailObject.mail}</div>
				<div use:styles={{ fontS }} class="mail_s">Subject:</div>
				<div use:styles={{ fontS }} class="mail_s">{mailObject.subject}</div>
				<div class="mail_divider" />
				<div use:styles={{ fontM }} class="mail_m mail_span">{mailObject.salutation}</div>
				<div use:styles={{ fontM }} class="mail_m mail_span">{mailObject.message}</div>
				<div class="mail_divider" />
				<div class="button_container">
					<div on:click|preventDefault={close_read_mail} class="close" />
					<div on:click|preventDefault={() => archive_cmd('delete', mailObject.id)} class="delete" />
				</div>
			</div>
		{/if}
	</div>

	{#if success}
		<div use:styles={{ fontM }} class="message success">{message}</div>
	{/if}

	{#if error}
		<div use:styles={{ fontM }} class="message error">{message}</div>
	{/if}
</div>

<style>
	.read_mail {
		display: grid;
		grid-template-columns: 30% 70%;
		row-gap: 4%;
	}

	.mail_s {
		font-size: var(--fontS);
	}

	.mail_m {
		font-size: var(--fontM);
	}

	.mail_span {
		grid-column-start: 1;
		grid-column-end: 3;
		margin-bottom: 3%;
	}

	.mail_select {
		user-select: none;
	}

	.mail_divider {
		min-height: 5%;
		border-bottom: 1px solid hsl(0, 0%, 60%);
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.container {
		display: flex;
		flex-direction: column;
		height: 80vh;
	}

	.button_container {
		display: flex;
		width: 100%;
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.archive_list {
		display: flex;
		flex-direction: column;
		height: 60%;
		width: 90%;
		padding: 2%;
		border: 1px solid var(--shadow);
		background-color: hsl(0, 0%, 95%);
		box-shadow: 3px 3px 3px var(--shadow);
		overflow-y: auto;
	}

	.archive_subject {
		font-size: var(--fontS);
		cursor: pointer;
	}

	.archive_subject:hover {
		color: blue;
	}

	.archive_entry {
		display: grid;
		grid-template-columns: 45% 15% 30% 10%;
		color: var(--color);
		margin-bottom: 2%;
		user-select: none;
	}

	.archive_entry:hover {
		background-color: hsl(0, 0%, 90%);
	}

	.archive_entry_options,
	.archive_entry_date {
		font-size: var(--fontSX);
		color: var(--color);
		user-select: none;
	}

	form {
		display: flex;
	}

	ul {
		padding: 0;
		width: 100%;
	}

	li {
		list-style: none;
	}

	input {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 60%;
		margin-right: 2%;
	}

	input:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	button {
		align-self: end;
		margin-right: 4%;
		border-radius: 5px;
		font-size: var(--fontSX);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.message {
		display: flex;
		width: 90%;
		font-size: var(--fontM);
		margin-top: 5%;
	}

	.delete {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/bin.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.close {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/close.png');
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 4%;
	}

	.success {
		color: green;
	}

	.error {
		color: red;
	}
</style>
