<!-- left block 2 -->
<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles.js'
	export const font_x = getContext('font-X')
	export const font_xs = getContext('font-XS')
	export const font_m = getContext('font-M')
	export const font_s = getContext('font-S')
	export const font_main_color = getContext('font-main-color')
</script>

<div class="container">
	<div use:styles={{ font_m, font_main_color }} class="text" data-title>ÖV</div>
	<ul use:styles={{ font_m, font_main_color }}>
		<li>Ab Zug und Baar Bus Nummer 3<br />Richtung Lättich</li>
		<li>Ab Baar ZVB Bus Nummer 31 <br /> Richtung Sihlbrugg Dorf</li>
		<li>Ab Baar ZVB Bus Nummer 32 <br /> Richtung Neuheim</li>
		<li>Bis Haltestelle Baar Paradies</li>
		<li>Zu Fuss Richtung Höllgrotten (10min)</li>
	</ul>

	<div use:styles={{ font_m, font_main_color }} class="text" data-title>Direktion</div>
	<ul use:styles={{ font_m, font_main_color }}>
		<li>Von Baar: Weststrasse Richtung Zürich</li>
		<li>Vor Kreisel Wegweiser Richtung Höllgrotten</li>
		<li>Von Zürich: vor Autobahn Sihlbruggstrasse Richtung Baar</li>
		<li>Nach Kreisel Wegweiser Richtung Höllgrotten</li>
	</ul>
</div>

<style>
	.container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.text {
		font-size: var(--font_m);
		color: var(--font_main_color);
		cursor: default;
		user-select: none;
	}

	.text[data-title] {
		font-size: var(--font_m);
		color: var(--font_main_color);
		font-weight: bold;
	}

	ul {
		font-size: var(--font_m);
		color: var(--font_main_color);
		font-weight: normal;
		cursor: default;
		user-select: none;
	}
</style>
