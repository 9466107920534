<script>
	import { getContext } from 'svelte'
	import { slide } from 'svelte/transition'
	import { startSendPhp, endSendPhp } from '../scripts/store.js'
	import { adminSingleReceiver, adminSingleReceiverValue, adminReceiver } from '../../admin/AdminModule.svelte'
	import { styles } from '../scripts/styles.js'
	import { send_image } from './SendImage.svelte'
	import { validate_mail } from '../scripts/validation.js'

	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')
	export const shadow = getContext('shadow-color')

	let startSend
	let endSend
	let receiver

	let loader = false
	let flyer = false
	let deleteImageButton = false
	let validImage = true

	adminReceiver.subscribe((value) => {
		receiver = value
	})

	//the mailinput if single mail is selected
	let singleReceiver = false
	let singleMailValue = null

	//subscribe the values to update page
	adminSingleReceiver.subscribe((value) => {
		singleReceiver = value
	})

	adminSingleReceiverValue.subscribe((value) => {
		singleMailValue = value
	})

	//the image, mail and text inputs
	let image
	let email = ''
	let title = ''
	let date = ''
	let time = ''
	let location = ''
	let message = ''

	//error state and message
	let error = false
	let errorMessage = ''

	startSendPhp.subscribe((value) => {
		startSend = value
	})

	endSendPhp.subscribe((value) => {
		endSend = value
	})

	const select_receiver = (receiver) => {
		adminReceiver.set(receiver)

		//single receiver -> set single receiver to true (shows email inputfield)
		if (receiver === 'single') {
			singleReceiver = true
			return
		}

		singleReceiver = false
	}

	const imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i

	//error messages
	const show_error = (message) => {
		errorMessage = message
		const sendForm = document.querySelector('form')
		sendForm.style.filter = 'blur(2px)'
		setTimeout(() => {
			error = false
			sendForm.style.filter = 'blur(0px)'
		}, 2500)
	}

	const save_image = () => {
		let imageObject = document.querySelector('#image').files[0]
		let imageValue = document.querySelector('#image')

		//**************** VALIDATION ******************/

		//image
		if (!imageObject.name.match(imageReg)) {
			validImage = false
			imageValue.value = null
			setTimeout(() => {
				delete_image('thumb_holder')
				validImage = true
			}, 2000)
			return
		}
		deleteImageButton = true
		image = imageObject

		const mailObject = {
			image: image,
		}

		send_image('thumb', mailObject, 100, 100, 'thumb_holder')
	}

	const send_form = () => {
		if (!image) {
			image = 'noimage'
		}

		//image validation if image or flyer is selected
		const checkImage = document.querySelector('#loader')
		const checkFlyer = document.querySelector('#flyer')

		if (checkImage.checked || checkFlyer.checked) {
			let imageObject = document.querySelector('#image').files[0]
			if (!imageObject) {
				error = true
				show_error('Kein Bild ausgewählt')
				return
			}
		}

		if ((singleReceiver && !validate_mail(email)) || (singleReceiver && email === '')) {
			error = true
			show_error('Keine oder ungültige Email Adresse')
			return
		}

		if (title === '') {
			error = true
			show_error('Keine Titel')
			return
		}

		if (date === '') {
			error = true
			show_error('Kein Datum')
			return
		}

		if (time === '') {
			error = true
			show_error('Keine Uhrzeit ')
			return
		}

		if (location === '') {
			error = true
			show_error('Kein Ort')
			return
		}

		//get the receiver from the hidden field of the form
		const sendGroup = document.querySelector('#receiver')
		//create the mailObject
		const mailObject = {
			flyer: flyer,
			receiver: sendGroup.value,
			image: image,
			mail: email,
			title: title,
			date: date,
			time: time,
			location: location,
			message: message,
		}

		startSendPhp.set(true)

		const sendForm = document.querySelector('form')
		sendForm.style.filter = 'blur(3px)'

		send_image('send', mailObject, 600, 600, 'thumb_holder')
	}

	const delete_image = (div) => {
		const divAttach = document.querySelector('.' + div + '')
		if (divAttach && divAttach.hasChildNodes()) {
			const clearImage = document.querySelector('#image')
			divAttach.removeChild(divAttach.firstChild)
			loader = false
			deleteImageButton = false
			clearImage.value = null
		}
	}

	//only one checkbox posiible
	const only_one = () => {
		const loaderBox = document.getElementById('loader')
		const flyerBox = document.getElementById('flyer')

		if (flyerBox.checked) {
			loaderBox.checked = false
			flyer = true
			return
		}

		if (loaderBox.checked) {
			flyerBox.checked = false
			flyer = false
			return
		}
	}

	// cleanup if all is done
	const cleanup = (rec) => {
		if (rec.receiver !== 'admin') {
			adminReceiver.set('admin')
			startSendPhp.set(false)
			deleteImageButton = false
			image = null
			email = ''
			title = ''
			date = ''
			time = ''
			location = ''
			message = ''

			//reset image input
			if (loader || flyer) {
				const clearImage = document.querySelector('#image')
				clearImage.value = null
				loader = false
				flyer = false
			}

			setTimeout(() => {
				const sendForm = document.querySelector('form')
				sendForm.style.filter = 'blur(0px)'

				//set radio button to test mail
				const clearRadio = document.querySelector('#test_mail')
				clearRadio.checked = true

				if (singleReceiver) {
					const clearMail = document.querySelector('#mail')
					clearMail.value = ''
					singleReceiver = false
				}

				const clearText = document.querySelector('#txt')
				if (clearText) {
					clearText.value = ''
				}

				const clearTitle = document.querySelector('#title')
				if (clearTitle) {
					clearTitle.value = ''
				}

				const clearDate = document.querySelector('#date')
				if (clearDate) {
					clearDate.value = ''
				}

				const clearTime = document.querySelector('#time')
				if (clearTime) {
					clearTime.value = ''
				}

				const clearLocation = document.querySelector('#location')
				if (clearLocation) {
					clearLocation.value = ''
				}

				const clearMessage = document.querySelector('#message')
				if (clearMessage) {
					clearMessage.value = ''
				}

				endSendPhp.set(false)
				delete_image('thumb_holder')
			}, 2500)
		}

		if (rec.receiver === 'admin') {
			startSendPhp.set(false)
			setTimeout(() => {
				const sendForm = document.querySelector('form')
				sendForm.style.filter = 'blur(0px)'
				endSendPhp.set(false)
			}, 2500)
		}

		return 'Email erfolgreich gesendet'
	}
</script>

<div class="container">
	<div class="title">Event erstellen</div>
	<ul>
		<li on:change|preventDefault={() => select_receiver('admin')} class="radio_button">
			<input type="radio" name="sendmail" checked="checked" id="test_mail" /><span
				use:styles={{ fontS }}
				class="margin_left">Test</span
			>
		</li>

		<li on:change|preventDefault={() => select_receiver('all')} class="radio_button">
			<input type="radio" name="sendmail" id="all_mail" /><span use:styles={{ fontS }} class="margin_left">An Alle</span
			>
		</li>

		<li on:change|preventDefault={() => select_receiver('single')} class="radio_button">
			<input type="radio" name="sendmail" id="single_mail" /><span use:styles={{ fontS }} class="margin_left"
				>Single</span
			>
		</li>

		<li class="check_box">
			<input type="checkbox" name="sendimage" id="loader" bind:checked={loader} on:click={only_one} />
			<span use:styles={{ fontS }} class="margin_checkbox">Bild</span>
		</li>

		<li class="check_box">
			<input type="checkbox" name="sendimage" id="flyer" bind:checked={flyer} on:click={only_one} />
			<span use:styles={{ fontS }} class="margin_checkbox">Flyer</span>
		</li>
	</ul>
	<form on:submit|preventDefault={send_form}>
		<input type="hidden" value={receiver} id="receiver" />
		{#if loader || flyer}
			<input
				transition:slide={{ duration: 1000 }}
				on:change={save_image}
				use:styles={{ shadow, fontM }}
				type="file"
				name="image"
				id="image"
				class="form_input"
			/>

			{#if !validImage}
				<div class="error">keine Bilddatei</div>
			{/if}
			<div transition:slide={{ duration: 1000 }} class="thumb_holder" />
		{/if}

		{#if singleReceiver}
			<input
				transition:slide={{ duration: 1000 }}
				use:styles={{ color, shadow, fontM }}
				type="text"
				name="mail"
				id="mail"
				placeholder="Email Adresse"
				bind:value={email}
			/>
		{/if}

		<input
			use:styles={{ color, shadow, fontM }}
			type="text"
			name="title"
			id="title"
			placeholder="Titel"
			bind:value={title}
		/>
		<div class="datetime">
			<input
				use:styles={{ color, shadow, fontM }}
				type="text"
				name="date"
				id="date"
				placeholder="Datum"
				bind:value={date}
			/>

			<input
				use:styles={{ color, shadow, fontM }}
				type="text"
				name="time"
				id="time"
				placeholder="Zeit"
				bind:value={time}
			/>
		</div>

		<input
			use:styles={{ color, shadow, fontM }}
			type="text"
			name="location"
			id="location"
			placeholder="Ort"
			bind:value={location}
		/>

		{#if !flyer}
			<textarea use:styles={{ color, shadow, fontM }} name="txt" id="txt" bind:value={message} placeholder="Info" />
		{/if}
		<div class="button_container">
			<button use:styles={{ shadow, fontM }} type="submit">senden</button>
			{#if deleteImageButton}
				<button use:styles={{ shadow, fontM }} on:click={() => delete_image('thumb_holder')}>bild löschen</button>
			{/if}
		</div>
	</form>

	{#if startSend}
		<div class="shader">
			<div use:styles={{ fontM }} class="spinner" />
			<div use:styles={{ fontM }} class="spinner-text">senden</div>
		</div>
	{/if}

	<!-- notify end of sending -->
	{#if endSend}
		<div class="shader">
			<div use:styles={{ fontM }} class="success">{cleanup({ receiver })}</div>
		</div>
	{/if}

	<!-- error messages -->
	{#if error}
		<div class="shader">
			<div use:styles={{ fontM }} class="error">{errorMessage}</div>
		</div>
	{/if}
</div>

<style>
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes load {
		0% {
			content: '. ';
		}

		25% {
			content: '..';
		}
		50% {
			content: '...';
		}
		75% {
			content: '....';
		}
		100% {
			content: '.....';
		}
	}

	.datetime {
		display: flex;
		width: 100%;
	}

	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 3%;
	}

	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		list-style: none;
		margin: 0% 0 2% 0;
		padding: 0;
		margin: 0;
		width: 80%;
	}

	li {
		margin-right: 2%;
		justify-self: center;
	}

	.margin_left,
	.margin_checkbox {
		margin-left: 5%;
		font-size: var(--fontS);
		user-select: none;
	}

	.margin_checkbox {
		margin-right: 5%;
	}

	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2%;
		width: 80%;
		height: 100%;
		opacity: 1;
		filter: blur(0px);
	}

	input[type='text'],
	input[type='file'],
	textarea {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 100%;
		resize: none;
	}

	input[type='text']:focus,
	input[type='file']:focus,
	textarea:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	input[type='checkbox'] {
		margin-right: 10%;
	}

	.thumb_holder {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		margin-bottom: 2%;
	}

	.button_container {
		width: 100%;
		display: flex;
	}

	/* .save_event {
		display: flex;
		justify-content: flex-start;
		width: 40%;
		margin-bottom: 2%;
	} */

	button {
		align-self: flex-end;
		border-radius: 5px;
		font-size: var(--fontS);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.radio_button {
		display: flex;
		font-size: 1rem;
		width: 30%;
		user-select: none;
	}

	.check_box {
		display: flex;
	}

	.error {
		color: red;
		font-size: var(--fontM);
		font-weight: bold;
	}

	.success {
		color: green;
		font-size: var(--fontM);
		font-weight: bold;
	}
	.shader {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}

	.spinner {
		border: solid var(--fontM) hsl(0, 0%, 60%);
		border-bottom-color: hsl(224, 88%, 53%);
		border-radius: 50%;
		height: clamp(5rem, 12vw, 6rem);
		width: clamp(5rem, 12vw, 6rem);
		animation: spin 1.5s linear infinite;
	}

	.spinner-text {
		display: flex;
		justify-content: flex-start;
		width: 20%;
		margin-top: 5%;
		font-size: var(--fontM);
	}

	.spinner-text::after {
		content: '';
		animation: load 1s linear infinite;
	}
</style>
