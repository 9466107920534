<script>
	import { getContext } from 'svelte'
	import { styles } from '../library/scripts/styles'
	import MailList from './MailList.svelte'
	import MailArchive from './MailArchive.svelte'

	export const fontM = getContext('font-M')
	export const fontS = getContext('font-S')
	export const color = getContext('font-main-color')

	//switch for change
	let switchView = false

	const select_list = (list) => {
		if (list === 'list') {
			switchView = false
			return
		}
		switchView = true
	}
</script>

<div class="title">Email Liste/Archiv</div>
<ul>
	<li on:change|preventDefault={() => select_list('list')} class="radio_button">
		<input type="radio" name="maillist" checked="checked" /><span use:styles={{ fontS }} class="margin_left"
			>Mail Liste</span
		>
	</li>

	<li on:change|preventDefault={() => select_list('archive')} class="radio_button">
		<input type="radio" name="maillist" /><span use:styles={{ fontS }} class="margin_left">Mail Archiv</span>
	</li>
</ul>

{#if !switchView}
	<MailList />
{:else}
	<MailArchive />
{/if}

<style>
	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 5%;
	}
	ul {
		display: flex;
		justify-content: space-around;
		list-style: none;
		margin: 2% 0 2% 0;
		padding: 0;
		width: 100%;
	}

	li {
		margin-right: 2%;
		justify-self: center;
	}

	.radio_button {
		display: flex;
		font-size: 1rem;
		width: 30%;
		user-select: none;
	}

	.margin_left {
		margin-left: 2%;
		font-size: var(--fontS);
	}
</style>
