<script>
	import { onMount } from 'svelte'
	import { urlAdmin } from '../library/Setup.svelte'
	import TwoCols from '../library/TwoCols.svelte'

	let categories = []
	let menu = []
	let rightContent = []
	let leftContent = []
	let noMenu = false

	const get_menu = async () => {
		const getData = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'get_menu',
			}),
		})

		const dataResponse = await getData.json()

		if (dataResponse.fetch === 0) {
			noMenu = true
			return
		}

		//create the menu objects
		dataResponse.fetch.forEach((item) => {
			const entryObject = {
				category: item[0],
				id: item[1],
				item: item[2],
				quantity: item[3],
				price: item[4],
				currency: item[5],
			}
			menu.unshift(entryObject)
		})

		//counter fro index
		let catIndex = 0

		//create the categories
		menu.forEach((item) => {
			if (!categories.includes(item.category)) {
				categories = [...categories, item.category]
				leftContent[catIndex] = []
				rightContent[catIndex] = []
				catIndex++
			}
		})

		//sort the entries
		let sortIndex = 0
		categories.forEach((category) => {
			menu.forEach((item) => {
				if (item.category === category) {
					leftContent[sortIndex].push(item)
					rightContent[sortIndex].push(item)
				}
			})

			sortIndex++
		})

		//calculate left and right content - if not equal, left part is longer
		const difference = rightContent.length % 2
		const spliceLeft = (rightContent.length + difference) / 2
		const spliceRight = (rightContent.length - difference) / 2
		leftContent = leftContent.splice(0, spliceLeft)
		rightContent = rightContent.splice(spliceLeft, spliceRight)
	}

	onMount(() => {
		get_menu()
	})
</script>

{#if !noMenu}
	<TwoCols>
		<div class="content" slot="leftPart">
			{#each leftContent as category}
				<div class="menu_title">{category[0].category}</div>
				{#each category as item}
					<div class="menu_sub_grid">
						<div class="menu_sub">{item.item}</div>
						<div class="menu_sub_small">{item.quantity}</div>
						<div class="menu_sub_small">{item.currency}</div>
						<div class="menu_sub_small">{item.price}</div>
					</div>
				{/each}
			{/each}
		</div>

		<div class="content" slot="rightPart">
			{#each rightContent as category}
				<div class="menu_title">{category[0].category}</div>
				{#each category as item}
					<div class="menu_sub_grid">
						<div class="menu_sub">{item.item}</div>
						<div class="menu_sub_small">{item.quantity}</div>
						<div class="menu_sub_small">{item.currency}</div>
						<div class="menu_sub_small">{item.price}</div>
					</div>
				{/each}
			{/each}
		</div>
	</TwoCols>
{/if}
{#if noMenu}
	<div class="error">noch kein Menue-Eintrag</div>
{/if}

<style>
	.content {
		height: 100%;
	}

	.menu_title {
		font-size: clamp(1rem, 1.2vw, 1.3rem);
		font-weight: bold;
		margin-bottom: 1%;
		color: hsl(0, 0%, 60%);
	}

	.menu_sub_grid {
		display: grid;
		grid-gap: 2%;
		grid-template-columns: 40% 12% 8% 8%;
		color: hsl(0, 0%, 60%);
	}

	.menu_sub {
		font-size: clamp(0.8rem, 1.1vw, 1.2rem);
		margin-bottom: 4%;
	}

	.menu_sub_small {
		font-size: clamp(0.8rem, 1.1vw, 1.2rem);
		justify-self: end;
		margin-bottom: 4%;
	}

	.error {
		display: flex;
		width: 100%;
		justify-content: center;
		color: red;
	}
</style>
