<script>
	import { getContext } from 'svelte'
	import { styles } from '../scripts/styles.js'
	import { contact } from '../scripts/store.js'
	import { recaptcha } from '../Setup.svelte'
	import { mailer } from './PhpMailer.svelte'

	//get the variables for mail from setup
	export const shadow = getContext('shadow-color')
	export const fontM = getContext('font-M')
	export const errorColor = getContext('error-color')
	export const color = getContext('font-main-color')

	//form variables
	let name
	let mail
	let msg
	let formData = {}

	//error handling
	let nameError = false
	let mailError = false
	let msgError = false

	//send and response handling
	let send = false
	let startSend = true
	let sendSuccess = false
	let sendError = false

	//messages
	let responseMessage

	const mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/

	const submitForm = () => {
		//client side error handling
		if (!name) {
			nameError = true
			const nameTimeout = setTimeout(() => {
				nameError = false
			}, 2000)
			return
		}

		if (!mail || !mail.match(mailformat)) {
			mailError = true
			const nameTimeout = setTimeout(() => {
				mailError = false
			}, 2000)
			return
		}

		if (!msg) {
			msgError = true
			const nameTimeout = setTimeout(() => {
				msgError = false
			}, 2000)
			return
		}

		//all ok we send the mail
		//get the recaptcha token - all remaining code must stay within this function
		window.grecaptcha.execute(recaptcha, { action: 'contactForm' }).then(function (token) {
			formData = { cmd: 'user_mail', name: name, mail: mail, msg: msg, recaptcha: token }
			// show shader with spinner
			send = true

			// set the form css blur to 2px
			const formElement = document.querySelector('form')
			formElement.style.opacity = '0.3'
			formElement.style.filter = 'blur(2px)'

			sendMail(formData)

			async function sendMail(formData) {
				const response = await mailer('send_mail', formData)

				//the message sent from the server
				responseMessage = response.code

				//success
				if (response.sent) {
					//hide the spinner
					startSend = false

					sendSuccess = true

					//reset form
					resetForm(true)
				}

				//error
				if (!response.sent) {
					sendError = true
					startSend = false
					resetForm(false)
				}
			}
		})
	}

	//function to reset form
	const resetForm = (status) => {
		const formElement = document.querySelector('form')

		//reset input and texarea if status is a success
		if (status) {
			formElement[0].value = ''
			formElement[1].value = ''
			formElement[2].value = ''

			setTimeout(() => {
				contact.set(false)
			}, 2000)
		}

		//hide all if send is finished
		setTimeout(() => {
			formElement.style.filter = 'blur(0px)'
			formElement.style.opacity = '1'
			send = false
			//reset the spinner
			startSend = true
			//reset previous error message
			sendError = false
		}, 2000)
	}
</script>

<div class="container">
	<!-- the mail send success fail infos and shader -->
	{#if send}
		<div class="shader">
			{#if startSend}
				<div use:styles={{ fontM }} class="spinner" />
				<div use:styles={{ fontM }} class="spinner-text">senden</div>
			{/if}
			{#if sendSuccess}
				<div use:styles={{ shadow, fontM }} class="mail" data-success>{responseMessage}</div>
			{/if}
			{#if sendError}
				<div use:styles={{ shadow, fontM }} class="mail" data-error>{responseMessage}</div>
			{/if}
		</div>
	{/if}

	<form on:submit|preventDefault={submitForm}>
		{#if !nameError}
			<input use:styles={{ shadow, fontM, color }} type="text" placeholder="Name" bind:value={name} />
		{/if}

		{#if nameError}
			<input use:styles={{ shadow, fontM }} type="text" placeholder="Name" bind:value={name} class="error" />
		{/if}

		{#if !mailError}
			<input use:styles={{ shadow, fontM, color }} type="text" placeholder="Email" bind:value={mail} />
		{/if}

		{#if mailError}
			<input use:styles={{ shadow, fontM }} type="text" placeholder="Email" bind:value={mail} class="error" />
		{/if}

		{#if !msgError}
			<textarea use:styles={{ shadow, fontM, color }} placeholder="Nachricht" rows="10" bind:value={msg} />
		{/if}

		{#if msgError}
			<textarea
				use:styles={{ shadow, fontM }}
				placeholder="Nachricht"
				rows="10"
				resize="none"
				bind:value={msg}
				class="error"
			/>
		{/if}

		<button use:styles={{ shadow, fontM }} type="submit">Senden</button>
	</form>
</div>

<style>
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes load {
		0% {
			content: '. ';
		}

		25% {
			content: '..';
		}
		50% {
			content: '...';
		}
		75% {
			content: '....';
		}
		100% {
			content: '.....';
		}
	}
	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.shader {
		position: absolute;
		top: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 82%;
		background-color: hsla(0, 0%, 40%, 0.2);
		z-index: 100;
	}

	.spinner {
		border: solid var(--fontM) hsl(0, 0%, 60%);
		border-bottom-color: hsl(224, 88%, 53%);
		border-radius: 50%;
		height: 15vw;
		width: 15vw;
		animation: spin 1.5s linear infinite;
	}

	.spinner-text {
		display: flex;
		justify-content: flex-start;
		width: 20%;
		margin-top: 5%;
		font-size: var(--fontM);
	}

	.spinner-text::after {
		content: '';
		animation: load 1s linear infinite;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2%;
		width: 80%;
		height: 100%;
		opacity: 1;
		filter: blur(0px);
	}

	input,
	textarea {
		box-shadow: 3px 3px 3px var(--shadow);
		font-size: var(--fontM);
		border-color: var(--shadow);
		color: var(--color);
		width: 100%;
		resize: none;
	}

	input:focus,
	textarea:focus {
		outline: none;
		border-color: green;
		color: var(--color);
	}

	button {
		align-self: flex-end;
		margin-right: 0%;
		border-radius: 5px;
		font-size: var(--fontM);
		box-shadow: 3px 3px 3px var(--shadow);
		cursor: pointer;
	}
	button:hover {
		background-color: var(--shadow);
	}

	.error {
		background-color: red;
	}

	.mail {
		display: flex;
		justify-content: center;
		font-size: var(--fontM);
		color: var(--color);
		width: 60%;
		text-align: center;
	}

	.mail[data-success] {
		color: green;
	}
	.mail[data-error] {
		color: red;
	}
</style>
