<script>
	import { onMount } from 'svelte'
	import { adminUpdateMenuEntry } from './AdminModule.svelte'
	import { urlAdmin } from '../library/Setup.svelte'

	let menu = []
	let categories = []

	const delete_item_from_db = async (id) => {
		const deleteItem = await fetch(urlAdmin, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				cmd: 'delete_entry',
				data: {
					id: id,
				},
			}),
		})

		const dataResponse = await deleteItem.json()
		return dataResponse.fetch
	}

	const delete_item = (id) => {
		const deleteFromDb = delete_item_from_db(id)
		if (deleteFromDb) {
			const menu = JSON.parse(sessionStorage.getItem('menu'))
			const newMenu = menu.filter((item) => {
				if (item.id != id) return item
			})

			adminUpdateMenuEntry.set(JSON.stringify(newMenu))
			sessionStorage.setItem('menu', JSON.stringify(newMenu))
		}
	}

	onMount(async () => {
		adminUpdateMenuEntry.subscribe((value) => {
			menu = JSON.parse(value)

			//reset categories
			if (menu != null) {
				categories.length = 0
				menu.forEach((item) => {
					if (!categories.includes(item.category)) categories = [...categories, item.category]
				})
			}
		})
	})
</script>

<div class="title">Menue Vorschau</div>
<!-- no entry yet -->
{#if menu === null || menu.length === 0}
	<div class="message error">Noch kein Eintrag vorhanden</div>
{:else}
	{#each categories as category}
		<div class="category">{category}</div>
		{#each menu as item}
			{#if item.category === category}
				<div class="item_grid">
					<div>{item.name}</div>
					<div class="align_right">{item.quantity}</div>
					<div class="align_right">{item.currency}</div>
					<div class="align_right">{item.price}</div>
					<div class="link" on:click={() => delete_item(item.id)} />
				</div>
			{/if}
		{/each}
		<div class="spacer" />
	{/each}
{/if}

<style>
	.title {
		font-size: clamp(1rem, 3vh, 1.5rem);
		font-weight: bold;
		color: hsl(0, 0%, 60%);
		margin-bottom: 3%;
	}
	.category {
		font-size: clamp(1rem, 1.2vw, 1.5rem);
		font-weight: bold;
		margin-bottom: 1%;
		cursor: default;
		user-select: none;
	}

	.item_grid {
		display: grid;
		grid-template-columns: 40% 10% 5% 5% 10%;
		font-size: clamp(0.8rem, 1.1vw, 1.1rem);
		grid-gap: 2%;
		cursor: default;
		user-select: none;
	}

	.link {
		cursor: pointer;
		width: 15px;
		height: 15px;
		background-image: url('/images/bin.png');
		background-repeat: no-repeat;
		background-size: cover;
	}

	.align_right {
		justify-self: end;
	}

	.spacer {
		min-height: 1.5vh;
	}

	.message {
		font-size: clamp(0.8rem, 1.1vw, 1.2rem);
	}

	.error {
		color: red;
	}
</style>
