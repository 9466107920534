<script>
	import { slide } from 'svelte/transition'
	import { open, menu, contact, location } from '../library/scripts/store.js'
	import LinkContent from '../componentParts/LinkContent.svelte'
	import OpenTimes from '../componentParts/OpenTimes.svelte'

	let visible

	open.subscribe((value) => {
		visible = value
	})

	const openComponent = () => {
		visible = visible === false ? true : false
		open.set(visible)

		//reset all open sliders
		menu.set(false)
		contact.set(false)
		location.set(false)
	}
</script>

<div on:click={openComponent}><LinkContent>Öffnungszeiten</LinkContent></div>

{#if visible}
	<div class="container" transition:slide={{ duration: 1000 }}>
		<div class="content">
			<OpenTimes />
		</div>
		<!-- <TwoCols>
			
			<FramedImage image_path="/images/IMG_1.jpg" slot="rightPart" />
		</TwoCols> -->
	</div>
{/if}

<style>
	.container {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.content {
		width: 60%;
	}
</style>
